import { PropsWithChildren } from "react";
import { placeNumberOffsetPx } from "src/components/TripPlanner/Headings/PlaceTitle";
import { color, spacing } from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import {
  desktopLayout,
  mobileLayout,
  useLayout,
} from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

type TripPlannerPlaceProps = PropsWithChildren<{
  index: number;
  isLast?: boolean;
  isDragging?: boolean;
  isBeingDragged?: boolean;
}>;

export function TripPlannerPlace({
  index,
  children,
  isDragging,
  isBeingDragged,
  isLast,
}: TripPlannerPlaceProps) {
  const { loadingIndex } = useTripPlannerContext();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const { isMultiTrip } = useTripPlannerContext();
  const layout = useLayout();

  return (
    <TripPlanEntryWrapper
      $isDragging={isDragging}
      $isBeingDragged={isBeingDragged}
      $hideTimeline={isLast}
      $loading={loadingIndex?.includes(index)}
      $isTripsAsCoreHoldback={isTripsAsCoreHoldback}
      $showOnlyTail={!isMultiTrip && layout === "desktop"}
      $isFirst={index === 0}
    >
      {children}
    </TripPlanEntryWrapper>
  );
}

const tripPlanTimeline = css`
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background-color: ${color.n40};
    position: absolute;

    left: calc(${placeNumberOffsetPx}px + ${spacing.md} - 1px);
    top: ${spacing.xl};
  }
`;

const newUitripPlanTimeline = css`
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background-color: ${color.n30};
    position: absolute;

    top: ${spacing.xxxl};
    left: -45px;
  }
`;

const showTailOnlyTimeline = css`
  &::before {
    content: "";
    height: 94px;
    width: 2px;
    display: block;
    position: absolute;
    background-color: ${color.n30};
    bottom: -${spacing.xxxl};
    left: ${spacing.xl};
  }
`;

export const TripPlanEntryWrapper = styled.li<{
  $hideTimeline?: boolean;
  $loading?: boolean;
  $isDragging?: boolean;
  $isBeingDragged?: boolean;
  $isTripsAsCoreHoldback?: boolean;
  $showOnlyTail?: boolean;
  $isFirst?: boolean;
}>`
  position: relative;
  display: ${({ $loading }) => ($loading ? "none" : "block")};
  width: 100%;
  list-style-type: none;
  ${A11yOutline};

  ${({ $isFirst }) =>
    $isFirst &&
    css`
      ${mobileLayout} {
        margin-top: ${spacing.lg};
      }
    `}

  ${mobileLayout} {
    ${({ $hideTimeline }) => !$hideTimeline && tripPlanTimeline};
  }

  ${desktopLayout} {
    ${({ $showOnlyTail, $hideTimeline }) =>
      !$hideTimeline &&
      ($showOnlyTail ? showTailOnlyTimeline : newUitripPlanTimeline)}
  }
`;
