import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { removeDisabledAdsUrlParams } from "src/utils/hooks/useDisableAdsUrlParams";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import {
  useIsHotelsUrlDeeplink,
  useNavigateToHotelsPage,
} from "src/utils/hooks/useNavigateToHotelsPage";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import styled, { css } from "styled-components";
import { logDataPartnerCarHireExit } from "src/data-partners/logDataPartnerExit";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { generateExitPoint } from "../../analytics/generateExitPoint/generateExitPoint";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { useLogExitCallback } from "../../analytics/useLogExitCallback/useLogExitCallback";
import { Drawer } from "../../components/Drawer/Drawer";
import { Icon } from "../../components/Icon/Icon";
import { UserPreferencesButton } from "../../components/UserPreferencesButton/UserPreferencesButton";
import { VisuallyHidden } from "../../components/VisuallyHidden/VisuallyHidden";
import { useFeature } from "../../feature/useFeature";
import { Menu } from "../../svg/Menu";
import { Rome2rioLogo } from "../../svg/Rome2rioLogo";
import { color, fontWeight, spacing } from "../../theme";
import { preferredNameFromPlace } from "../../utils/adapters/place";
import { localeToLanguageCode } from "../../utils/conversions/languageCode";
import { useFallbackPlaceForDeeplink } from "../../utils/hooks/useFallbackPlaceForDeeplink";
import { largeDesktopLayout, useLayout } from "../../utils/hooks/useLayout";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import { useHotelDestinationFromUrl } from "../../utils/hooks/useHotelDestinationFromUrl";
import { getCarHireDeeplinkRedirectUrl, getHomepageUrl } from "../../utils/url";
import { MyTripsModal } from "../TripPlanner/MyTripsModal/MyTripsModal";
import { UserPreferencesScreen } from "../UserPreferencesScreen/UserPreferencesScreen";
import { useExitOnHExEntry } from "../HotelsScreen/utils-exit-to-provider";
import { DebugDropdown } from "./DebugDropdown/DebugDropdown";
import { HelpDropdown } from "./HelpDropdown/HelpDropdown";
import { messages } from "./TopNav.messages";
import { UserDropdown } from "./UserDropdown/UserDropdown";
import { NavButton } from "./NavButton";

export const topNavHeight = {
  default: "56px",
  large: "64px",
};

export function TopNav() {
  const hexIsExpedia = useFeature("HExIsExpedia");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const intl = useIntl();
  const layout = useLayout();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const isHotelScreen = useIsHotelsUrlDeeplink();
  const isTripsScreen = useIsTripScreen();

  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const languageCode = localeToLanguageCode(intl.locale);
  const homepageUrl = getHomepageUrl(languageCode);
  const logCarExit = useLogExitCallback(
    "RentalCars",
    "genericMap",
    "headerCarHire"
  );
  const carHireExitPoint = generateExitPoint("genericMap", "headerCarHire");
  const { searchResponse, origin, destination } = useSearch();
  const intendedDestination = useHotelDestinationFromUrl();
  const deeplinkPlaceFallback = useFallbackPlaceForDeeplink();
  const deeplinkLocation = preferredNameFromPlace(
    intendedDestination ?? deeplinkPlaceFallback
  );
  const { navigateToHotels, hotelsHash } = useNavigateToHotelsPage();
  const { isSmScreen } = useScreenMinWidth();

  const tripsHash = "#trips";
  const navigateToTrips = function () {
    location.search = removeDisabledAdsUrlParams(location);
    navigateToNewStateHash(
      navigate,
      { highlightedTab: "trips" },
      tripsHash,
      {
        ...location,
      },
      true
    );
  };

  const navigateToTransport = function () {
    location.search = removeDisabledAdsUrlParams(location);
    navigateToNewStateHash(
      navigate,
      { highlightedTab: "search" },
      undefined,
      location
    );
  };

  const carHireUrl = getCarHireDeeplinkRedirectUrl(
    searchResponse?.request.requestId,
    carHireExitPoint,
    deeplinkLocation,
    deeplinkPlaceFallback.lat,
    deeplinkPlaceFallback.lng
  );

  const isDebug = useFeature("Debug");
  const accomCtaMessage = intl.formatMessage(messages.hotels);

  return (
    <Nav isLargeNav={isSmScreen}>
      <VisuallyHidden>
        <h2>{intl.formatMessage(messages.primaryNavigation)}</h2>
      </VisuallyHidden>

      {isSmScreen ? (
        <>
          <LogoOption $small as="div">
            <LogoLink
              href={homepageUrl}
              onClick={() =>
                sendAnalyticsInteractionEvent("TopNav", "Click:Home")
              }
            >
              <Rome2rioLogo
                title={intl.formatMessage(messages.homepage)}
                tint={theme.topNav.iconTint}
                dotTint="pink"
              />
            </LogoLink>
          </LogoOption>

          <NavOptions $fullWidth>
            <MyTripsModal />

            <NavButton $small $isActive={isHotelScreen}>
              <Link
                href={hotelsHash}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnalyticsInteractionEvent("TopNav", "Click:Hotels");

                  if (hexIsExpedia && handleExpediaExit) {
                    handleExpediaExit({
                      lat: destination?.lat,
                      lng: destination?.lng,
                      canonicalName: destination?.canonicalName,
                    });
                  } else {
                    navigateToHotels();
                  }
                }}
              >
                {accomCtaMessage}
              </Link>
            </NavButton>

            <NavButton $small>
              <Link
                href={carHireUrl}
                onClick={() => {
                  sendAnalyticsInteractionEvent("TopNav", "Click:CarHire");
                  logDataPartnerCarHireExit({
                    origin: origin?.canonicalName,
                    destination: destination?.canonicalName,
                    languageCode,
                    isCrimtanEnabled:
                      searchResponse?.analytics?.interest_data?.Crimtan
                        ?.enabled,
                    isAdaraEnabled:
                      searchResponse?.analytics?.interest_data?.Adara?.enabled,
                  });
                  logCarExit(carHireUrl);
                }}
                target="_blank"
                rel="noopener nofollow"
              >
                {intl.formatMessage(messages.cars)}
              </Link>
            </NavButton>

            <Spacer />
            <NavOption $medium>
              <UserPreferencesButton size="xl" />
            </NavOption>
            <NavOption $medium>
              <HelpDropdown size="xl" />
            </NavOption>
            <DividerDiv />
            <NavOption>
              <UserDropdown />
            </NavOption>

            {isDebug && (
              <NavOption $medium>
                <DebugDropdown />
              </NavOption>
            )}
          </NavOptions>
        </>
      ) : (
        <>
          <NavOptions>
            <LogoOption>
              <LogoLink
                href={homepageUrl}
                onClick={() =>
                  sendAnalyticsInteractionEvent("TopNav", "Click:Home")
                }
              >
                <Rome2rioLogo
                  title={intl.formatMessage(messages.homepage)}
                  tint={theme.topNav.iconTint}
                  dotTint="pink"
                />
              </LogoLink>
            </LogoOption>

            <NavOption $underline $active={location.hash === ""} noLeftPadding>
              <Link
                href={location.pathname}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnalyticsInteractionEvent("TopNav", "Click:Transport");
                  navigateToTransport();
                }}
              >
                {intl.formatMessage(messages.transport)}
              </Link>
            </NavOption>

            <NavOption $underline $active={isHotelScreen}>
              <Link
                href={hotelsHash}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnalyticsInteractionEvent("TopNav", "Click:Hotels");
                  navigateToHotels();
                }}
              >
                {accomCtaMessage}
              </Link>
            </NavOption>

            <NavOption $underline $active={isTripsScreen}>
              <Link
                href={tripsHash}
                onClick={(e) => {
                  e.preventDefault();
                  sendAnalyticsInteractionEvent("TopNav", "Click:Trips");
                  navigateToTrips();
                }}
              >
                {intl.formatMessage(messages.trips)}
              </Link>
            </NavOption>

            <NavOption $underline>
              <Link
                href={carHireUrl}
                onClick={() => {
                  sendAnalyticsInteractionEvent("TopNav", "Click:CarHire");
                  logCarExit(carHireUrl);
                }}
                target="_blank"
                rel="noopener nofollow"
              >
                {intl.formatMessage(messages.cars)}
              </Link>
            </NavOption>
          </NavOptions>

          <NavOptions>
            <NavOption>
              <UserPreferencesButton />
            </NavOption>

            <NavOption>
              <HelpDropdown />
            </NavOption>

            <NavOption>
              <UserDropdown />
            </NavOption>

            {layout === "tablet" && (
              <>
                <NavOption>
                  <MenuButton
                    onClick={() => {
                      sendAnalyticsInteractionEvent("TopNav", "Click:Menu");
                      setIsOpen(true);
                    }}
                  >
                    <Icon size="xl">
                      <Menu
                        title={intl.formatMessage(messages.preferences)}
                        tint={theme.topNav.iconTint}
                      />
                    </Icon>
                  </MenuButton>
                </NavOption>
                <Drawer
                  isOpen={isOpen}
                  anchor="right"
                  onBackdropClicked={() => setIsOpen(false)}
                  onCloseClicked={() => setIsOpen(false)}
                  hideCloseButton={true}
                >
                  <UserPreferencesScreen />
                </Drawer>
              </>
            )}
            {isDebug && (
              <NavOption>
                <DebugDropdown />
              </NavOption>
            )}
          </NavOptions>
        </>
      )}
    </Nav>
  );
}

export const Nav = styled.nav<{ isLargeNav: boolean }>`
  background-color: ${(props) => props.theme.topNav.background};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 32px;
  /* Specific height so it stays the same height when switch logo svgs between tablet and desktop */
  min-height: ${(props) =>
    props.isLargeNav ? topNavHeight.large : topNavHeight.default};
`;

const NavOptions = styled.ol<{ $fullWidth?: boolean }>`
  width: ${(props) => (props.$fullWidth ? "100%" : "unset")};
  list-style: none;
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const DividerDiv = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${color.n30};
  margin-left: ${spacing.md};
`;

export const LogoOption = styled.li<{ $small?: boolean }>`
  display: flex;
  align-items: center;
  padding-right: 28px;
  ${({ $small }) =>
    $small &&
    css`
      padding-right: ${spacing.lg};
    `}
  ${largeDesktopLayout} {
    padding-right: 58px;
  }
`;

const HoverUnderline = css<{ $active?: boolean }>`
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 0;
    height: 4px;
    left: 50%;
    background-color: ${(props) => props.theme.topNav.textHover};
    transition: width 0.3s cubic-bezier(0.22, 0.68, 0, 1.21),
      left 0.3s cubic-bezier(0.22, 0.68, 0, 1.21);
  }

  ${(props) => {
    if (props.$active) {
      return css`
        &:after {
          width: 90%;
          left: 5%;
          background-color: ${(props) => props.theme.topNav.borderActive};
        }
      `;
    }
  }}

  &:hover {
    &:after {
      width: 90%;
      left: 5%;
    }
  }
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
  width: 160px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.topNav.text};
  text-decoration: none;
  display: inline-block;
  font-weight: ${fontWeight.medium};
  padding: ${spacing.lg};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.topNav.textHover};
  }
`;

const NavOption = styled.li<{
  noLeftPadding?: boolean;
  $underline?: boolean;
  $active?: boolean;
  $small?: boolean;
  $medium?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${(props) => (props.$medium ? spacing.md : spacing.sm)};
  padding-left: ${(props) => (props.noLeftPadding ? 0 : undefined)};
  ${largeDesktopLayout} {
    padding: 0 ${spacing.lg};
  }

  &:first-child {
    padding-left: 0;
  }

  & *:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${({ $active }) =>
    $active &&
    css`
      > *:hover {
        color: ${(props) => props.theme.topNav.text};
      }
    `}

  ${({ $underline }) => $underline && HoverUnderline}
`;

const MenuButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: ${spacing.md};

  &:hover {
    /* This isn't great, but it's the only way we can style an icon on hover at the moment */
    svg path {
      fill: ${(props) => props.theme.topNav.textHover};
    }
  }
`;
