import { interactionEntitySchemaURI } from "src/api/snowplowIdentifiers";
import logBreadcrumbs from "src/utils/logBreadcrumbs";
import { UNSAFE_featureConfigEscapeHatch } from "../feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { AnalyticsEventCategory } from "./analyticsEventTypes";

export function sendAnalyticsEvent(
  category: AnalyticsEventCategory,
  action: string,
  label?: string,
  value?: number,
  isNonInteraction?: boolean
) {
  const sampleRate = Math.min(
    parseSampleRate(category),
    parseSampleRate(action)
  );
  const isSelectedSample = Math.random() <= sampleRate;

  const isUsingSnowplowAnalytics = isSelectedSample && window.snowplow;

  if (UNSAFE_featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    logToConsole(category, action, label, value, isNonInteraction);
  }

  if (!isNonInteraction) {
    logBreadcrumbs(category, action, label, value);
  }

  if (isUsingSnowplowAnalytics) {
    window.snowplow("trackStructEvent", {
      category,
      action,
      label,
      property: undefined,
      value: value?.toString(),
      context: [
        {
          schema: interactionEntitySchemaURI,
          data: {
            interaction: !isNonInteraction,
          },
        },
      ],
    });
  }
}

function parseSampleRate(value: string): number {
  const isSampled = value.match(/\[sample=(0\.\d+)\]/);
  return isSampled ? parseFloat(isSampled[1]) : 1;
}

function logToConsole(
  category: string,
  action: string,
  label?: string,
  value?: number,
  isNonInteraction?: boolean
) {
  const stack = new Error().stack;
  let callLine: string[] = [];
  if (stack) {
    const callStack = stack.split("\n")[3];
    const callFile = callStack.split("/").pop();
    if (callFile) {
      callLine = callFile.split(":");
    }
  }

  console.log(
    `» Sp %c${category} %c${isNonInteraction ? "ni" : ""}%c${action ?? ""} %c${
      label ?? ""
    } %c${value ?? ""} %c[${callLine[0] + ":" + callLine[1]}]`,
    "color:blue;",
    "color:grey;border:solid 1px grey;font-size:9px;border-radius:2px;margin-right:6px;",
    "color:green;",
    "color:#48104d;",
    "color:#999;",
    "color:#aaa;font-size:90%"
  );
}
