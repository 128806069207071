import { FormattedMessage, useIntl } from "react-intl";
import { useFeature } from "src/feature/useFeature";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import messages from "./messages";

const CTA_COPY_TRANSLATIONS = {
  portuguese: {
    ViewOffer: "Ver oferta",
    CheckOutTheHotel: "Confira o hotel",
    SeeTheHotel: "Veja o hotel",
    SeeMore: "Ver mais",
    SeeDetails: "Ver detalhes",
    ViewOptions: "Ver opções",
  },
  french: {
    MoreInfo: "Plus d'infos",
    DisplayDeal: "Afficher l'offre",
    SeeHotel: "Voir l'hotel",
    SeeStay: "Voir le séjour",
    ViewNow: "Voir maintenant",
    ViewTheOptions: "Voir les options",
  },
  spanish: {
    ViewDeal: "Ver oferta",
    FindDeal: "Encontrar oferta",
    SeeHotel: "Ver hotel",
    SeeDetails: "Ver detalles",
    ViewNow: "Ver ahora",
  },
  german: {
    FindDeal: "Angebot finden",
    ViewMore: "Mehr ansehen",
    SeeDetails: "Details ansehen",
    View: "Mehr erfahren",
  },
};

export function CtaCopyTest() {
  const ctaTestPortuguese = useFeature("HExCTACopyPortuguese");
  const ctaTestFrench = useFeature("HExCTACopyFrench");
  const ctaTestSpanish = useFeature("HExCTACopySpanish");
  const ctaTestGerman = useFeature("HExCTACopyGerman");

  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);

  if (languageCode === "pt" && ctaTestPortuguese !== "Baseline") {
    return CTA_COPY_TRANSLATIONS.portuguese[ctaTestPortuguese];
  }

  if (languageCode === "fr" && ctaTestFrench !== "Baseline") {
    return CTA_COPY_TRANSLATIONS.french[ctaTestFrench];
  }

  if (languageCode === "es" && ctaTestSpanish !== "Baseline") {
    return CTA_COPY_TRANSLATIONS.spanish[ctaTestSpanish];
  }

  if (languageCode === "de" && ctaTestGerman !== "Baseline") {
    return CTA_COPY_TRANSLATIONS.german[ctaTestGerman];
  }

  return <FormattedMessage {...messages.callToAction} />;
}
