import { ReactElement, useCallback, useEffect, useState } from "react";

import { PaneStack } from "src/components/PaneStack/PaneStack";
import { color } from "src/theme";
import { SearchOverrideProvider } from "src/utils/hooks/SearchOverrideProvider";
import {
  ReturnStage,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import styled from "styled-components";
import { isValidSegment } from "src/domain/SegmentScreen/useEnsureValidSegment";
import { useIsRouteSkipped } from "src/utils/hooks/useIsRouteSkipped";
import LazyHotelsScreen from "src/domain/HotelsScreen/LazyHotelsScreen";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import {
  getStackedPaneWidth,
  TRIP_PANE_GAP,
  useStackPaneWidth,
} from "src/utils/hooks/useGetStackedMapOffset";
import { isValidRoute } from "src/domain/RouteScreen/useEnsureValidRoute";
import { useScroll } from "src/ScrollContext";
import DestinationScreen from "src/domain/DestinationScreen/DestinationScreen";
import { useFeature } from "src/feature/useFeature";
import { MemoizedTripPlannerTransport } from "../Transport/TripPlannerTransport";
import { useTripSearchResponse } from "../hooks/useTripSearchResponse";
import { getScreenKey } from "../util/getScreenKey";
import { useTripRouteSegmentIndex } from "../hooks/useTripSegment";
import { useTripRouteIndex } from "../hooks/useTripRoute";
import { useClosePane } from "../hooks/useClosePane";
import { getOriginDestinationFromHash } from "../util/getOriginDestinationFromHash";

type Props = {
  TripPlannerContent: ReactElement;
  transportIndex?: number;
  returnsFlowLocation?: ReturnStage;
};

export function StackedNavigation({
  transportIndex,
  returnsFlowLocation,
  TripPlannerContent,
}: Props) {
  const location = useTypedLocation();
  const { closePane } = useClosePane();
  const { setScrollTop } = useScroll();
  const screenKey = getScreenKey(location.hash);

  const { tripSearchResponse, tripOrigin, tripDestination } =
    useTripSearchResponse();
  const routeIndex = useTripRouteIndex();
  const routeSegmentIndex = useTripRouteSegmentIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const [validSegment, setValidSegment] = useState<boolean | undefined>();
  const isRoutePane =
    isValidRoute(tripSearchResponse, routeIndex) && !validSegment;
  const isSearchScreen = routeIndex === undefined && screenKey === "transport";
  const isDestinationScreen = screenKey === "destination";
  const isTripBreakpointsFeature = useFeature("TripBreakpoints");
  const isNewTripPaneWidth =
    isRoutePane || isSearchScreen || isDestinationScreen;

  const paneWidth = useStackPaneWidth(screenKey);

  const activePaneIndex = ["destination", "hotels", "transport"].includes(
    screenKey || ""
  )
    ? 1
    : 0;

  // If the active pane index is set to 0, the transport flow is animating out, and a rerender isn't necessary.
  // A rerender of the transport flow as it is animating out is expensive (and jittery), so this check prevents any animation jitter.
  const hideTransportFlow = activePaneIndex === 0 || screenKey !== "transport";

  // This effect resets the scroll position when navigating into the hotels screen.
  useEffect(() => {
    if (screenKey === "hotels") {
      setScrollTop(0);
    }
  }, [screenKey, setScrollTop]);

  const checkValidSegment = useCallback(() => {
    const isValid = isValidSegment(
      tripSearchResponse,
      routeIndex,
      routeSegmentIndex,
      isRouteSkipped
    );

    setValidSegment(isValid);
  }, [isRouteSkipped, routeIndex, routeSegmentIndex, tripSearchResponse]);

  // In order to calculate whether the desktop two column experience should
  // be applied, we first need to check if the segment is valid.
  // We do not render the pane until this has been determined.
  useEffect(() => {
    if (tripSearchResponse !== undefined && activePaneIndex === 1) {
      checkValidSegment();
    }
  }, [checkValidSegment, tripSearchResponse, activePaneIndex]);

  function closePaneClick() {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Dismiss:StackedNav",
      screenKey
    );
    closePane();
  }

  const { origin, destination } =
    getOriginDestinationFromHash(location.hash) ?? {};

  return (
    <PaneStack
      activePaneIndex={activePaneIndex}
      paneGap={TRIP_PANE_GAP}
      paneWidth={
        isNewTripPaneWidth || isTripBreakpointsFeature
          ? paneWidth
          : getStackedPaneWidth(getScreenKey(location.hash))
      }
      BottomPane={TripPlannerContent}
      onBackdropClick={closePaneClick}
    >
      <ScreenContainer>
        <SearchOverrideProvider
          value={{
            searchResponse: tripSearchResponse!,
            origin: tripOrigin,
            destination: tripDestination,
          }}
        >
          {isDestinationScreen && (
            <DestinationScreen
              origin={!!destination ? origin : undefined}
              destination={destination ?? origin}
            />
          )}
          {!hideTransportFlow && validSegment !== undefined && (
            <MemoizedTripPlannerTransport
              context="transport"
              transportIndex={transportIndex}
              returnsFlowLocation={returnsFlowLocation}
            />
          )}
          {screenKey === "hotels" && <LazyHotelsScreen />}
        </SearchOverrideProvider>
      </ScreenContainer>
    </PaneStack>
  );
}

const ScreenContainer = styled.div`
  width: 100%;
  background-color: ${color.n20};
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
