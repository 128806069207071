import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import messages from "src/App.messages";
import styled from "styled-components";
import richmondMapImage from "./Richmond.webp";

export function MapLoadingPlaceholder() {
  const intl = useIntl();

  return (
    <MapLoadingImg
      // This placeholder is 400*400px.
      // Any image in the viewport exceeding 160,000px will overtake this as the Largest Contentful Element.
      src={richmondMapImage}
      alt={intl.formatMessage(messages.mapLoading)}
      aria-hidden
    />
  );
}

export function useIsMapTilesLoaded(map: google.maps.Map | null) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const listener = map?.addListener("tilesloaded", () => setIsLoaded(true));
    return () => listener?.remove();
  }, [map]);

  return isLoaded;
}

const MapLoadingImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: blur(12px);
  transform: scale(1.1);
  transform-origin: center;
  pointer-events: none;
`;
