import { useIntl } from "react-intl";
import { border_radius } from "src/design-system/tokens/border";
import { spacing } from "src/design-system/tokens/spacing";
import { color } from "src/theme";
import styled from "styled-components";
import messages from "./TripPlannerCard.messages";

export default function ErrorMessage() {
  const intl = useIntl();
  return (
    <ErrorContainer>
      <p>{intl.formatMessage(messages.transportError)}</p>
    </ErrorContainer>
  );
}

const ErrorContainer = styled.div`
  padding: ${spacing.lg};
  border-radius: ${border_radius.rounded_md};
  background-color: ${color.white};
`;
