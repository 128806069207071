import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { spacing } from "src/theme";
import {
  isHotjarInitialized,
  sendHotjarEvent,
} from "src/utils/hooks/useHotjar";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useLayout } from "src/utils/hooks/useLayout";
import useUser from "src/utils/hooks/useUser";
import styled from "styled-components";
import { AddDestinationButton } from "src/components/TripPlanner/AddDestinationButton/AddDestinationButton";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import scrollIntoView from "scroll-into-view-if-needed";
import { useFeature } from "src/feature/useFeature";
import { FeedbackButton } from "../HotjarFeedbackButton/FeedbackButton";
import messages from "./Onboarding/TripPlannerOnboarding.messages";
import { OnscreenReorder } from "./ReorderTrip/OnscreenReorder/OnscreenReorder";
import {
  LoadingCard,
  TripPlannerCard,
} from "./TripPlannerCard/TripPlannerCard";
import {
  TripPlanEntryWrapper,
  TripPlannerPlace,
} from "./TripPlannerCard/TripPlannerPlace";
import { UserPrompt } from "./UserPrompt/UserPrompt";
import { useUserPrompt } from "./UserPrompt/useUserPrompt";
import { useTripPlannerContext } from "./hooks/useTripPlannerContext";
import CarRentalPromo from "./CarRentalPromo/CarRentalPromo";
import AddDestinationInline from "./AddDestinationInline/AddDestinationInline";
import { HotelsPromo } from "./HotelsPromo/HotelsPromo";
import { UserAccountTakeover } from "./UserAccountTakeover/UserAccountTakeover";

export function TripPlannerScreenLoaded() {
  const {
    tripPlannerDetails,
    tripPlanningState,
    setHoveredPlaceIndex,
    tripInteraction,
    tripDestination,
  } = useTripPlannerContext();

  const { isLoading, setDestination, shortName, longName } = tripDestination;

  const {
    hasBeenClosed,
    handleCloseUserPrompt,
    showUserPrompt,
    showUserPromptOverlay,
    handleSaveTripUserPrompt,
    handleClosePromptOverlay,
    userPromptRef,
  } = useUserPrompt();

  const intl = useIntl();
  const layout = useLayout();
  const isLargerThanMobile = layout !== "mobile";
  const isEnglish = useUser().language === "en";
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const showFeedbackButton =
    isEnglish && tripInteraction.isInteractionMade && isHotjarInitialized();
  const scrollRef = useRef<HTMLDivElement>(null);
  const addDestinationRef = useRef<HTMLDivElement>(null);
  const isTripsV2OnMobile =
    useFeature("TripsV2OnMobile") && !isLargerThanMobile;

  function onSelectDestination(place: AutocompletePlace) {
    if (place.link) {
      window.open(place.link, "_blank");
    } else {
      setDestination(place);
    }
  }

  function handleFeedbackButtonClick() {
    const type = isLargerThanMobile ? "hsb" : "mobile";
    sendHotjarEvent(`show_trip_planner_${type}_feedback_survey`);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollRef.current) {
        scrollIntoView(scrollRef.current, {
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [tripPlanningState.updatedPlace.value]);

  // Scroll up to add destination inline when last place removed
  function handleRemoveOfLast() {
    setTimeout(() => {
      if (addDestinationRef.current) {
        scrollIntoView(addDestinationRef.current, {
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  }

  return (
    <>
      {showUserPrompt && !hasBeenClosed && !isTripsV2OnMobile && (
        <UserPrompt
          hasBeenClosed={hasBeenClosed}
          onSave={handleSaveTripUserPrompt}
          onClose={handleCloseUserPrompt}
          ref={userPromptRef}
        />
      )}

      {showUserPromptOverlay && (
        <UserAccountTakeover
          handleClickOffDropdown={handleClosePromptOverlay}
          handleClose={handleClosePromptOverlay}
          isDropdownOpen
        />
      )}

      {isLargerThanMobile ? (
        <OnscreenReorder onRemove={handleRemoveOfLast} />
      ) : (
        <>
          {tripPlannerDetails.places.map((p, index) => {
            const key = `${index}_tripCard_${p.canonicalName}`;
            const callbacks = {
              hoverCallback: () => setHoveredPlaceIndex(index),
              unHoverCallback: () => setHoveredPlaceIndex(undefined),
            };
            const isUpdated = tripPlanningState.updatedPlace.value === index;
            return (
              <TripPlannerPlace
                isLast={index === tripPlannerDetails.places.length - 1}
                index={index}
                key={key}
              >
                <TripPlannerCard
                  scrollRef={isUpdated ? scrollRef : undefined}
                  places={tripPlannerDetails.places}
                  index={index}
                  callbacks={callbacks}
                  isUpdated={isUpdated}
                />
              </TripPlannerPlace>
            );
          })}
        </>
      )}

      {isLoading && isTripsAsCoreHoldback && (
        <LoadingContainer>
          <TripPlanEntryWrapper $hideTimeline={true} $isTripsAsCoreHoldback>
            <LoadingCard
              name={longName ?? shortName}
              index={tripPlannerDetails.places.length}
            />
          </TripPlanEntryWrapper>
        </LoadingContainer>
      )}

      <TripPlanEntryWrapper $hideTimeline={true}>
        {layout !== "mobile" ? (
          <AddDestinationInline
            addDestinationRef={addDestinationRef}
            onSelectDestination={onSelectDestination}
          />
        ) : (
          <AddDestinationButton onSelectDestination={onSelectDestination} />
        )}
      </TripPlanEntryWrapper>

      {showUserPrompt && hasBeenClosed && (
        <UserPrompt
          hasBeenClosed={hasBeenClosed}
          onSave={handleSaveTripUserPrompt}
          onClose={handleCloseUserPrompt}
          ref={userPromptRef}
        />
      )}

      <PromoButtons>
        {isTripsAsCoreHoldback && tripPlannerDetails.places.length === 2 && (
          <HotelsPromo
            location={tripPlannerDetails.places[1]}
            // TODO: When desktop trips has it's own tab, we can just pass TripPlanner.
            eventCategory={isTripsV2OnMobile ? "TripPlanner" : undefined}
          />
        )}
        {isTripsAsCoreHoldback && (
          <CarRentalPromo
            // TODO: When desktop trips has it's own tab, we can just pass TripPlanner.
            eventCategory={isTripsV2OnMobile ? "TripPlanner" : undefined}
          />
        )}
      </PromoButtons>

      {showFeedbackButton && (
        <FeedbackButtonContainer>
          <FeedbackButton
            backgroundColor="transparent"
            fontWeight="normal"
            data-testid="trip-planner-feedback-button"
            message={intl.formatMessage(
              isLargerThanMobile
                ? messages.feedbackLink
                : messages.feedbackLinkMobile
            )}
            onFeedbackButtonClick={handleFeedbackButtonClick}
          />
        </FeedbackButtonContainer>
      )}
    </>
  );
}

const FeedbackButtonContainer = styled.div`
  padding: 0 ${spacing.xl};
`;

const PromoButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  margin-top: ${spacing.xxxl};
`;

const LoadingContainer = styled.div`
  padding-left: 50px;
`;
