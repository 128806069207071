import {
  contentSourcesEntitySchemaURI,
  experimentEntitySchemaURI,
  requestIdentifiersEntitySchemaURI,
  screenContentEntitySchemaURI,
  screenLayoutEntitySchemaURI,
  searchEntitySchemaURI,
  seoStoreEntitySchemaURI,
  serverRequestEntitySchemaURI,
  windowIdentifiersEntitySchemaURI,
  gtmPreviewURI,
} from "src/api/snowplowIdentifiers";
import { Mode } from "src/utils/types/mode";
import { SnowplowContext } from "src/utils/types/snowplow";
import { Analytics } from "../api/SearchResponse";
import { UNSAFE_featureConfigEscapeHatch } from "../feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { browserDomId, browserSessionId } from "./browserIdentifier";
import { formatGaLocationsLabel } from "./formatGaLocationsLabel/formatGaLocationsLabel";
import { ScreenLocations } from "./getLocations/getLocations";
import { Screen } from "./generateScreenComponentsRendered/Screen";
import { ScreenComponent } from "./generateScreenComponentsRendered/ScreenComponent";

type cachedSnowplowGlobalContextType =
  | {
      serverRequestContext?: SnowplowContext;
      windowIdentifiersContext?: SnowplowContext;
      requestIdentifiersContext?: SnowplowContext;
      searchContext?: SnowplowContext;
      seoStoreContext?: SnowplowContext;
      experimentsContext?: SnowplowContext;
      contentSourcesContext?: SnowplowContext;
      screenLayoutContext?: SnowplowContext;
      screenContentContext?: SnowplowContext;
      gtmPreviewContext?: SnowplowContext;
    }
  | undefined;

let cachedSnowplowGlobalContext: cachedSnowplowGlobalContextType = undefined;

export type GaPageConfig = {
  pagePath: string;
  pageLocation: string;
};

export type CustomDimensions = Analytics["custom_dimensions"];

type configureAnalyticsParams = {
  pageConfig: GaPageConfig;
  customDimensions?: CustomDimensions;
  screenLabel?: string;
  modeLabel?: string;
  locations?: ScreenLocations;
  userId?: string;
  screen?: Screen;
  modes?: Mode[];
  components?: ScreenComponent[];
};

export function configureAnalytics({
  pageConfig,
  customDimensions = {},
  locations,
  userId,
  screen,
  modes,
  components,
}: configureAnalyticsParams) {
  const domId = browserDomId();
  const localSessionId = browserSessionId();
  const screenLabel = screen
    ? `v1:${screen}:${(components || []).join(":")}`
    : undefined;
  const modeLabel = screen
    ? `v1:${screen}:${(modes || []).join(":")}`
    : undefined;
  const locationLabel =
    screen && locations ? formatGaLocationsLabel(screen, locations) : undefined;

  const commonConfig = {
    ...customDimensions, // this must come first in case we need to override anything
    send_page_view: false,
    page_path: pageConfig.pagePath,
    page_location: pageConfig.pageLocation,
    dimension48: domId,
    dimension49: localSessionId,
    ...(screenLabel && { dimension45: screenLabel }),
    ...(modeLabel && { dimension46: modeLabel }),
    ...(locationLabel && { dimension47: locationLabel }),
    ...(userId && {
      dimension50: userId,
    }),
  };

  const isCustomDimensionsPresent = Object.keys(customDimensions).length !== 0;

  if (!!window.snowplow) {
    const serverRequestContext = isCustomDimensionsPresent
      ? createServerRequestContext(customDimensions)
      : cachedSnowplowGlobalContext?.serverRequestContext;

    const windowIdentifiersContext = createWindowIdentifiersContext(
      domId,
      localSessionId
    );
    const requestIdentifiersContext = isCustomDimensionsPresent
      ? createRequestIdentifierContext(customDimensions)
      : cachedSnowplowGlobalContext?.requestIdentifiersContext;
    const searchContext = isCustomDimensionsPresent
      ? createSearchContext(customDimensions)
      : cachedSnowplowGlobalContext?.searchContext;
    const seoStoreContext = isCustomDimensionsPresent
      ? createSeoStoreContext(customDimensions)
      : cachedSnowplowGlobalContext?.seoStoreContext;
    const experimentsContext = isCustomDimensionsPresent
      ? createExperimentsContext(customDimensions)
      : cachedSnowplowGlobalContext?.experimentsContext;
    const contentSourcesContext = isCustomDimensionsPresent
      ? createContentSources(customDimensions)
      : cachedSnowplowGlobalContext?.contentSourcesContext;
    const screenLayoutContext =
      screen || components
        ? createScreenLayoutContext(screen, components)
        : cachedSnowplowGlobalContext?.screenLayoutContext;
    const screenContentContext =
      screen || modes || locations
        ? createScreenContentContext(screen, modes, locations)
        : cachedSnowplowGlobalContext?.screenContentContext;

    /**
     * USER GUIDE : Enable GTM preview mode in snowplow pipeline
     * 1. Enter Preview Mode in Google Tag Manager
     * 2. Click on the three dots in the top right corner of the screen and click “Send requests manually”
     * 3. You will see a popup with a preview header, for example (not a real value): ZW52LTh8UDlyMDNRRk1ZWU1MSFRzRWx0QVJ6QXwxOTFlOTg2ZTcxYjAzNDlhNjgwMzE=
     * 4. Copy the header value and set it as the value for gtmPreviewHeader variable
     * 5. Update index.html to log to the production collector url when in dev mode
     */
    const gtmPreviewHeader = null;
    const gtmPreviewContext = gtmPreviewHeader
      ? creategtmPreviewContext(gtmPreviewHeader)
      : cachedSnowplowGlobalContext?.gtmPreviewContext;

    const latestSnowplowGlobalContext = {
      serverRequestContext,
      windowIdentifiersContext,
      requestIdentifiersContext,
      searchContext,
      seoStoreContext,
      experimentsContext,
      contentSourcesContext,
      screenLayoutContext,
      screenContentContext,
      gtmPreviewContext,
    };

    cachedSnowplowGlobalContext = latestSnowplowGlobalContext;
    window.snowplow("clearGlobalContexts");
    window.snowplow(
      "addGlobalContexts",
      Object.values(latestSnowplowGlobalContext)
    );
    if (userId) {
      window.snowplow("setUserId", userId);
    }
  }

  if (UNSAFE_featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    console.log("» %cConfigure Sp", "color:green;", commonConfig);
  }
}

function createRequestIdentifierContext(customDimensions: CustomDimensions) {
  return {
    schema: requestIdentifiersEntitySchemaURI,
    data: {
      Uid: customDimensions?.dimension41,
      Aqid: customDimensions?.dimension42,
      RequestId: customDimensions?.dimension40,
    },
  };
}

function createWindowIdentifiersContext(domId: String, localSessionId: String) {
  return {
    schema: windowIdentifiersEntitySchemaURI,
    data: {
      BrowserDomId: domId,
      BrowserSessionId: localSessionId,
    },
  };
}

function createServerRequestContext(customDimensions: CustomDimensions) {
  return {
    schema: serverRequestEntitySchemaURI,
    data: {
      R2rBotName: customDimensions?.dimension1,
      R2rLanguage: customDimensions?.dimension37,
      R2rServer: customDimensions?.dimension3,
      R2rPageKind: customDimensions?.dimension36,
    },
  };
}

function createSearchContext(customDimensions: CustomDimensions) {
  return {
    schema: searchEntitySchemaURI,
    data: {
      Terms: [
        {
          Canonical: customDimensions?.dimension21,
          Kind: customDimensions?.dimension23,
          City: customDimensions?.dimension27,
          CountryCode: customDimensions?.dimension25,
          GeoCoderSource: customDimensions?.dimension43,
        },
        {
          Canonical: customDimensions?.dimension22,
          Kind: customDimensions?.dimension24,
          City: customDimensions?.dimension28,
          CountryCode: customDimensions?.dimension26,
          GeoCoderSource: customDimensions?.dimension44,
        },
      ],
    },
  };
}

function createSeoStoreContext(customDimensions: CustomDimensions) {
  return {
    schema: seoStoreEntitySchemaURI,
    data: {
      Source: customDimensions?.dimension9,
      Weight: customDimensions?.dimension10
        ? Number(customDimensions?.dimension10)
        : undefined,
      FrequencyBand: customDimensions?.dimension33,
    },
  };
}

function createExperimentsContext(customDimensions: CustomDimensions) {
  return {
    schema: experimentEntitySchemaURI,
    data: {
      BackendEnrolmentsEncoded: customDimensions?.dimension32,
    },
  };
}

function createContentSources(customDimensions: CustomDimensions) {
  return {
    schema: contentSourcesEntitySchemaURI,
    data: {
      Agencies: customDimensions?.dimension35,
      DataSources: customDimensions?.dimension38,
    },
  };
}

function createScreenLayoutContext(
  screen?: Screen,
  components?: ScreenComponent[]
) {
  return {
    schema: screenLayoutEntitySchemaURI,
    data: {
      Screen: screen,
      Components: components,
    },
  };
}

function createScreenContentContext(
  screen?: Screen,
  modes?: Mode[],
  locations?: ScreenLocations
) {
  return {
    schema: screenContentEntitySchemaURI,
    data: {
      Screen: screen,
      TravelKinds: modes,
      Locations: locations
        ? [
            {
              name:
                locations.origin.canonicalName ?? locations.origin.shortName,
              kind: locations.origin.kind,
            },
            {
              name:
                locations.destination.canonicalName ??
                locations?.destination.shortName,
              kind: locations.destination.kind,
            },
          ]
        : undefined,
    },
  };
}

function creategtmPreviewContext(previewHeader: String) {
  return {
    schema: gtmPreviewURI,
    data: {
      "x-gtm-server-preview": previewHeader,
    },
  };
}
