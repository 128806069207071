import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

export const layoutMinWidths = {
  // Change to tablet early until we ship user accounts and less in topnav
  tablet: 768,
  desktop: 1024,
};

export type LayoutType = "desktop" | "tablet" | "mobile";

/**
 * @deprecated Restructure styles to use `mediumBreakpoint` instead.
 */
export const desktopLayout = `
  @media screen and (min-width: ${layoutMinWidths.tablet}px)
`;

/**
 * @deprecated Restructure styles to use `largeBreakpoint` instead.
 */
export const largeDesktopLayout = `
  @media screen and (min-width: ${layoutMinWidths.desktop}px)
`;

/**
 * @deprecated Restructure styles to use `smallBreakpoint` instead.
 */
export const tabletLayout = `
  @media not screen and (min-width: ${layoutMinWidths.desktop}px)
`;

/**
 * @deprecated Styles should be mobile by default.
 */
export const mobileLayout = `
  @media not screen and (min-width: ${layoutMinWidths.tablet}px)
`;

export const smallMobileLayout = `
  @media screen and (max-width: 350px)
`;

/**
 * You shouldn't be using this. If you want to make a component responsive use CSS media
 * queries directly. If you want to change the layout based on device type, this isn't
 * what you're looking for.
 *
 * If you are really sure you need to use this, you should use useSmallMediaQuery,
 * useMediumMediaQuery, or useLargeMediaQuery instead.
 *
 * @deprecated Use CSS media queries directly instead of this hook, or, use the hooks in useScreenSize.ts.
 */
export function useLayout(
  onLayoutChanged?: (layout: LayoutType, lastLayout: LayoutType) => void
): LayoutType {
  const isDesktop = useMediaQuery({ minWidth: layoutMinWidths.desktop });
  const isTablet = useMediaQuery({ minWidth: layoutMinWidths.tablet });

  // This Ref is true once the first update has been executed, it's used
  // to ensure we don't call the onLayoutChanged callback as part of the first
  // update.
  const isUpdateRef = useRef(false);
  useEffect(() => {
    isUpdateRef.current = true;
  }, []);

  // Because we don't want to re-run the callback useEffect we need to hold
  // the reference to onLayoutChanged in a Ref.
  const onLayoutChangedRef = useRef(onLayoutChanged);
  useEffect(() => {
    onLayoutChangedRef.current = onLayoutChanged;
  }, [onLayoutChanged]);

  const lastLayoutRef = useRef<LayoutType>();

  let result: LayoutType;

  if (isDesktop) {
    result = "desktop";
  } else if (isTablet) {
    result = "tablet";
  } else {
    result = "mobile";
  }

  useEffect(() => {
    if (
      isUpdateRef.current &&
      onLayoutChangedRef.current &&
      lastLayoutRef.current
    ) {
      onLayoutChangedRef.current(result, lastLayoutRef.current);
    }
  }, [result]);

  // We must keep track of the "last layout" so that we can send it to
  // the onLayoutChanged callback the next time the layout changes.
  useEffect(() => {
    lastLayoutRef.current = result;
  }, [result]);

  return result;
}
