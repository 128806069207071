import {
  AdvancedMarker,
  Map as VisGlMap,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import {
  GOOGLE_MAP_ID,
  MAP_BOUNDS_RESTRICTION,
} from "src/components/Map/constants";
import styled from "styled-components";

import { useEffect } from "react";
import { centerZoomMapForBounds } from "src/components/Map/centerZoomMapForSegments";
import { RoutePolylines } from "src/components/Map/internal/components/RoutePolylines";
import SolidMapPin from "src/svg/SolidMapPin.svg?react";
import { ITALY_CENTER } from "../TripPlanner/Recommendations/constants";
import { useDiscoverContext } from "./DiscoverProvider";
import { Pin } from "./Pin/Pin";
import useDiscover from "./hooks/useDiscover";

export function DiscoverMap({
  originRequest,
}: {
  originRequest?: {
    lat: number;
    lng: number;
    canonicalName: string;
  };
}) {
  const { data: places } = useDiscover(originRequest);
  const showPins = !!(originRequest && places?.length);
  const map = useMap();
  const { LatLngBounds } = useMapsLibrary("core") || {};

  const { selectedCanonical, setSelectedCanonical } = useDiscoverContext();

  useEffect(() => {
    if (places && map && LatLngBounds && originRequest) {
      const bounds = new LatLngBounds();
      Object.values(places).forEach((place) => {
        bounds.extend({ lat: place.lat, lng: place.lng });
      });
      centerZoomMapForBounds({ map, bounds });
    }
  }, [map, originRequest, places, LatLngBounds]);

  function handleMapClick() {
    setSelectedCanonical(undefined);
  }

  return (
    <MapDiv>
      <VisGlMap
        mapId={GOOGLE_MAP_ID}
        gestureHandling="greedy"
        fullscreenControl={false}
        clickableIcons={false}
        mapTypeControl={false}
        restriction={MAP_BOUNDS_RESTRICTION.restriction}
        defaultZoom={7}
        defaultCenter={ITALY_CENTER}
        streetViewControl={false}
        onClick={handleMapClick}
      >
        {originRequest ? <OriginMarker origin={originRequest} /> : null}
        {showPins && map
          ? places.map((place) => (
              <>
                <Pin origin={originRequest} destination={place} />
                <RoutePolylines
                  googleMap={map}
                  from={originRequest}
                  to={place}
                  highlightedRoute={
                    selectedCanonical === place.canonicalName ? 0 : undefined
                  }
                  isFitBounds={false}
                  count={1}
                />
              </>
            ))
          : null}
      </VisGlMap>
    </MapDiv>
  );
}

function OriginMarker({
  origin,
}: {
  origin: { canonicalName: string; lat: number; lng: number };
}) {
  const { lat, lng } = origin;
  return (
    <AdvancedMarker position={{ lat, lng }} zIndex={1}>
      <SolidMapPin />
    </AdvancedMarker>
  );
}

const MapDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none; // To prevent mobile browsers from being refreshed when a user drags down on the map
  position: relative;
`;
