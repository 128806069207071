import { useHotelList } from "src/utils/hooks/useHotelList";
import { HotelProviderKind } from "src/utils/types/accommodationProviders";
import { getTimeInTimeZone } from "../SegmentScreen/SearchDatesPicker/DateHelpers";
import { default as listMessages } from "./HotelListCard/HotelListCard.messages";

// Any utility that discerns between hotel providers should be kept here.
// This will help us to correctly onboard new providers in future.

export function getRatingInfoMessage(
  rating: number,
  provider: HotelProviderKind
) {
  // BookingCom has a user rating out of 10 whereas Expedia is out of 5.
  if (provider === "Expedia") {
    rating *= 2;
  }
  if (rating > 9.5) {
    return listMessages.ratingExceptional;
  } else if (rating > 9.0) {
    return listMessages.ratingSuperb;
  } else if (rating > 8.5) {
    return listMessages.ratingFabulous;
  } else if (rating > 8.0) {
    return listMessages.ratingVeryGood;
  } else if (rating > 7.0) {
    return listMessages.ratingGood;
  } else if (rating > 6.0) {
    return listMessages.ratingPleasant;
  }
  return listMessages.ratingPassable;
}

// Filter / sort.

// The names for amenities change across languages, and the provider gives us them translated.
// We need to map from amenity code (which is also provider specific) to the name in the current language.
type ProviderAmenityLookups = "wifi";
export function useGetProviderSpecificAmenityCode(
  amenity: ProviderAmenityLookups
) {
  const { hotelListResponse } = useHotelList();
  const provider = hotelListResponse?.provider;

  switch (provider) {
    case "BookingCom": {
      const codeMap = {
        wifi: 96,
      };
      return codeMap[amenity];
    }
    case "Expedia": {
      const codeMap = {
        wifi: 2,
      };
      return codeMap[amenity];
    }
    case "Agoda": {
      const codeMap = {
        wifi: 109,
      };
      return codeMap[amenity];
    }
    default: {
      return -1;
    }
  }
}

// Images.
export type HotelImageSizes = {
  thumbnail: string;
  regular: string;
  detailed: string;
  square: string;
};

const bookingComImgCodes: HotelImageSizes = {
  thumbnail: "max20", // 20px wide
  regular: "max500", // 500px wide
  square: "square600", // 600px by 600px
  detailed: "max1000", // 1000px wide
};
const bookingRegexPattern = /(max|square|original)[0-9]\d{0,3}/;

const expediaImgCodes: HotelImageSizes = {
  thumbnail: "_t.jpg", // 70px by 70px
  regular: "_b.jpg", // 350px by 255px
  square: "_y.jpg", // 500px by 500px
  detailed: "_z.jpg", // 1000px by 1000px
};
const expediaRegexPattern = /(_[a-z].jpg|.jpg).*/;

export function getSizedImagesFromProvider(
  provider: HotelProviderKind | undefined,
  src: string | undefined
): HotelImageSizes | undefined {
  if (!provider || !src) {
    return;
  }

  if (provider === "Expedia" && src.match(expediaRegexPattern)) {
    return {
      thumbnail: src.replace(expediaRegexPattern, expediaImgCodes.thumbnail),
      regular: src.replace(expediaRegexPattern, expediaImgCodes.regular),
      square: src.replace(expediaRegexPattern, expediaImgCodes.square),
      detailed: src.replace(expediaRegexPattern, expediaImgCodes.detailed),
    };
  } else if (provider === "BookingCom" && src.match(bookingRegexPattern)) {
    return {
      thumbnail: src.replace(bookingRegexPattern, bookingComImgCodes.thumbnail),
      regular: src.replace(bookingRegexPattern, bookingComImgCodes.regular),
      square: src.replace(bookingRegexPattern, bookingComImgCodes.square),
      detailed: src.replace(bookingRegexPattern, bookingComImgCodes.detailed),
    };
  }
  return {
    thumbnail: src,
    regular: src,
    square: src,
    detailed: src,
  };
}

export function getDefaultAgodaDates(
  isMobile: boolean,
  timeZone: string
): [Date, Date] {
  const DESKTOP_CHECK_IN_DAYS_AHEAD = 30;
  const MOBILE_CHECK_IN_DAYS_AHEAD = 1;
  const STAY_LENGTH = 1;

  const checkIn = getTimeInTimeZone(timeZone);
  checkIn.setDate(
    checkIn.getDate() +
      (isMobile ? MOBILE_CHECK_IN_DAYS_AHEAD : DESKTOP_CHECK_IN_DAYS_AHEAD)
  );
  checkIn.setHours(0, 0, 0, 0);
  const checkOut = new Date(checkIn);
  checkOut.setDate(checkOut.getDate() + STAY_LENGTH);
  checkOut.setHours(0, 0, 0, 0);

  return [checkIn, checkOut];
}
