import { PropsWithChildren } from "react";
import { darken } from "polished";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { color } from "../../../theme";
import { LayoutType, useLayout } from "../../../utils/hooks/useLayout";

type Props = PropsWithChildren<{
  id?: string;
  backgroundColor?: keyof typeof color;
  hideBorderBottom?: boolean;
  removeMinHeight?: boolean;
  roundedTopCorners?: boolean;
  className?: string;
}>;

// Destructuring assignment to set some values by default
export function SectionHeader({
  id,
  backgroundColor,
  hideBorderBottom,
  removeMinHeight,
  roundedTopCorners = true,
  className,
  children,
}: Props) {
  const layout = useLayout();

  return (
    <SegmentSectionWrapper
      id={id}
      backgroundColor={backgroundColor}
      hideBorderBottom={hideBorderBottom}
      removeMinHeight={removeMinHeight}
      roundedTopCorners={roundedTopCorners}
      className={className}
      layout={layout}
    >
      {children}
    </SegmentSectionWrapper>
  );
}

const SegmentSectionWrapper = styled.div<{
  backgroundColor: Props["backgroundColor"];
  hideBorderBottom?: boolean;
  roundedTopCorners?: boolean;
  removeMinHeight?: boolean;
  layout: LayoutType;
}>`
  display: flex;
  align-items: center;
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  min-height: ${(props) => (props.removeMinHeight ? null : "56px")};
  border-bottom: ${(props) =>
    props.hideBorderBottom ? `none` : `1px solid ${color.grey2}`};

  ${(props) => {
    if (props.backgroundColor) {
      const backgroundColor = color[props.backgroundColor];
      return `
        background-color: ${backgroundColor};
        border-top: 1px solid ${darken(0.05, backgroundColor)};
        border-bottom: 1px solid ${darken(0.05, backgroundColor)};
      `;
    }
  }};

  ${(props) => {
    if (props?.layout !== "mobile" && props.roundedTopCorners) {
      return `
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      `;
    }
  }};
`;
