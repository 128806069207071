import { ForwardedRef, forwardRef } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { border_radius } from "src/design-system/tokens/border";
import styled from "styled-components";
import { Icon } from "../../../components/Icon/Icon";
import { Calendar } from "../../../svg/Calendar";
import { color } from "../../../theme";
import { MergeElementProps } from "../../../utils/MergeElementProps";
import { useTheme } from "../../../utils/hooks/useTheme";
import { DestinationIcon } from "./DestinationIcon";
import { OriginIcon } from "./OriginIcon";
import messages from "./SearchInput.messages.ts";

type Kind = "origin" | "destination" | "date" | "hotelDestination";

export type SearchInputProps = MergeElementProps<
  "input",
  {
    kind: Kind;
    value?: string;
    hint?: string;
    className?: string;
  }
>;

export const SearchInput = forwardRef(
  (props: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { kind, value, hint, ...inputProps } = props;

    const intl = useIntl();
    const valueOrEmpty = props.value ?? "";
    const hintOrEmpty = props.hint ?? "";
    const label = labelFromKind(props.kind);
    const placeholder = placeholderFromKind(props.kind);
    return (
      <TextFieldContainer className={props.className}>
        <HintInput
          type="text"
          value={props.hint ? `${valueOrEmpty}${hintOrEmpty}` : ""}
          disabled
          autoComplete="off"
          autoCapitalize="off"
          aria-label={`${intl.formatMessage(label)}, ${intl.formatMessage(
            messages.hint
          )}`}
        />
        <InputIcon size="lg">
          <LeftIcon kind={props.kind} />
        </InputIcon>
        <Input
          {...inputProps}
          type="search"
          placeholder={intl.formatMessage(placeholder)}
          value={valueOrEmpty}
          aria-label={intl.formatMessage(label)}
          spellCheck={false}
          ref={ref}
        />
      </TextFieldContainer>
    );
  }
);

function labelFromKind(kind: Kind): MessageDescriptor {
  switch (kind) {
    case "origin":
      return messages.originLabel;
    case "destination":
      return messages.destinationLabel;
    case "hotelDestination":
      return messages.destinationLabel;
    case "date":
      return messages.dateLabel;
  }
}

function placeholderFromKind(kind: Kind): MessageDescriptor {
  switch (kind) {
    case "origin":
      return messages.originPlaceholder;
    case "destination":
      return messages.destinationPlaceholder;
    case "date":
      return messages.datePlaceholder;
    case "hotelDestination":
      return messages.hotelDestinationPlaceholder;
  }
}

function LeftIcon({ kind }: { kind: Kind }) {
  const theme = useTheme();
  switch (kind) {
    case "origin":
      return <OriginIcon tint={theme.searchBar.input.iconTint} />;
    case "hotelDestination":
    case "destination":
      return <DestinationIcon tint={theme.searchBar.input.iconTint} />;
    case "date":
      return <Calendar tint={theme.searchBar.input.iconTint} />;
  }
}

const BaseInput = styled.input`
  // Reset and remove browser-specific styles so that we can style the input
  // ourselves.
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  display: block;
  min-width: 0;
  min-height: 48px;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.searchBar.input.borderFocus};
  }

  // Remove styles applied to the input because we specify use type=search.
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
`;

export const Input = styled(BaseInput)`
  position: relative;
  padding-left: 41px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 15px;
  border: 1px solid ${(props) => props.theme.searchBar.input.border};
  color: ${(props) => props.theme.searchBar.input.text};

  &::placeholder {
    color: ${(props) => props.theme.searchBar.input.placeholder};
  }
`;

const TextFieldContainer = styled.div`
  border-radius: ${border_radius.rounded_sm};
  background-color: ${(props) => props.theme.searchBar.input.background};
  cursor: text;
  display: inline-flex;
  position: relative;
  width: 100%;
`;

const InputIcon = styled(Icon)`
  cursor: inherit;
  left: 16px;
  position: absolute;
  bottom: 50%;
  top: 50%;
  transform: translate(0, -50%);
`;

const HintInput = styled(BaseInput)`
  position: absolute;
  padding-left: 41px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 15px;
  border: 1px solid ${color.transparent};
  color: ${(props) => props.theme.searchBar.input.hint};
`;
