import { useMediaQuery } from "react-responsive";

export const layoutMinWidths = {
  small: 640,
  medium: 768,
  large: 1024,
  xlarge: 1280,
  xxlarge: 1536,
};

export type ScreenSize = "large" | "medium" | "small";

export const smallBreakpoint = `
  @media (min-width: ${layoutMinWidths.small}px)
`;

/**
 * You shouldn't be using this. If you want to make a component responsive use CSS media
 * queries directly. If you want to change the layout based on device type, this isn't
 * what you're looking for.
 */
export function useSmallMediaQuery() {
  return useMediaQuery({ minWidth: layoutMinWidths.small });
}

export const mediumBreakpoint = `
  @media (min-width: ${layoutMinWidths.medium}px)
`;

/**
 * You shouldn't be using this. If you want to make a component responsive use CSS media
 * queries directly. If you want to change the layout based on device type, this isn't
 * what you're looking for.
 */
export function useMediumMediaQuery() {
  return useMediaQuery({ minWidth: layoutMinWidths.medium });
}

export const largeBreakpoint = `
  @media (min-width: ${layoutMinWidths.large}px)
`;

/**
 * You shouldn't be using this. If you want to make a component responsive use CSS media
 * queries directly. If you want to change the layout based on device type, this isn't
 * what you're looking for.
 */
export function useLargeMediaQuery() {
  return useMediaQuery({ minWidth: layoutMinWidths.large });
}

/**
 * You shouldn't be using this. If you want to make a component responsive use CSS media
 * queries directly. If you want to change the layout based on device type, this isn't
 * what you're looking for.
 *
 * Prefer using useLargeMediaQuery or useMediumMediaQuery instead.
 */
export function useScreenSize() {
  const isLarge = useLargeMediaQuery();
  const isMedium = useMediumMediaQuery();

  if (isLarge) {
    return "large";
  }
  if (isMedium) {
    return "medium";
  }
  return "small";
}
