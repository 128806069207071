import { useIntl } from "react-intl";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { Geocoded } from "../../PrefetchData";
import { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { GeocodeCanonicalResponse } from "../../api/GeocodeResponse";
import { geocodeCanonicalEndpoint } from "../../api/endpoints";
import { localeToLanguageCode } from "../conversions/languageCode";
import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import { SupportedLanguageCode } from "../language";

// useGeocode geocodes the provided canonical and returns the GeocodeResult
// associated. Note that the GeocodeResult returned may have a different
// canonical name than the canonical provided.
//
// It is safe to call useGeocode multiple times with the same canonical, the
// result is cached and will not result in an additional network call.
export function useGeocodeCanonical(canonical?: string) {
  const intl = useIntl();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();

  const geocodeResult = useQuery({
    queryKey: getCacheKeyForGeocode(canonical, languageCode),
    queryFn: async () => {
      return geocodeCanonical(config, canonical, languageCode, queryClient);
    },
    gcTime: ONE_DAY_IN_MILLISECONDS,
    staleTime: ONE_DAY_IN_MILLISECONDS,
    enabled: !!canonical,
    retryOnMount: false,
    retry: 3,
  });

  return geocodeResult;
}

export async function geocodeCanonical(
  config: ApiConfig,
  canonicalName: string | undefined,
  languageCode: SupportedLanguageCode,
  queryClient: QueryClient
): Promise<Geocoded> {
  const url = geocodeCanonicalEndpoint(config, canonicalName, languageCode);
  try {
    const response = await fetch(url, {
      referrerPolicy: "no-referrer-when-downgrade",
    });
    const json: GeocodeCanonicalResponse = await response.json();
    if (!json.place) {
      throw new Error("No places found");
    }
    const place = json.place;

    // When we geocode a canonical from a geocoder result, we aren’t guaranteed
    // to get the same geocode result back (yes, this is unfortunate). In an
    // effort to not unnecessarily geocode a canonical when we already have a
    // good geocoder result, we fake a mapping of the geocode result canonical
    // to the geocode result.
    //
    // For example, if a user opens the route /map/Bostonn/New-York we geocode
    // ‘Bostonn’ to ‘Boston-MA’. The URL is then updated from
    // /map/Bostonn/New-York to map/Boston-MA/New-York. This URL change would
    // trigger a geocode of ‘Boston-MA’ even though we already have the correct
    // geocode result from the initial geocode of Bostonn. This problem is
    // avoided since we’ve faked the mapping from Boston-MA to a geocode result
    // in the cache, which results in us reading from the cache for the
    // Boston-MA geocode instead of triggering another request.
    queryClient.setQueryDefaults(
      getCacheKeyForGeocode(place.canonicalName, languageCode),
      {
        gcTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
    queryClient.setQueryData(
      getCacheKeyForGeocode(place.canonicalName, languageCode),
      place
    );
    return place;
  } catch (error) {
    throw error;
  }
}

export function getCacheKeyForGeocode(
  term: string | undefined,
  languageCode: string
) {
  return ["place", term, languageCode];
}
