import { LayoutType, useLayout } from "src/utils/hooks/useLayout";
import {
  ReturnStage,
  useTypedLocation,
} from "../../utils/hooks/useTypedLocation";
import { useTripPlannerContext } from "./hooks/useTripPlannerContext";
import { MultiTripScreen } from "./MultiTrip/MultiTripScreen";
import { SingleTripScreen } from "./SingleTrip/SingleTripScreen";
import { getScreenKey } from "./util/getScreenKey";
import { SavedTripsScreen } from "./SavedTripsScreen/SavedTripsScreen";
import { CreateTripScreen } from "./CreateTripScreen/CreateTripScreen";

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function TripPlannerScreen({ returnsFlowLocation }: Props) {
  const { isMultiTrip } = useTripPlannerContext();
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);
  const layout = useLayout();
  const tripScreenKey = getTripScreenKey(isMultiTrip, layout, screenKey);
  const TripScreen = getTripScreen(tripScreenKey);

  return <TripScreen returnsFlowLocation={returnsFlowLocation} />;
}

type TripScreenKey = "multi" | "single" | "saved" | "create";

function getTripScreen(key: TripScreenKey) {
  switch (key) {
    case "multi":
      return MultiTripScreen;
    case "single":
      return SingleTripScreen;
    case "saved":
      return SavedTripsScreen;
    case "create":
      return CreateTripScreen;
  }
}

function getTripScreenKey(
  isMultiTrip: boolean,
  layout: LayoutType,
  screenKey?: string
): TripScreenKey {
  if (layout === "mobile") {
    switch (screenKey) {
      case "saved":
        return "saved";
      case "create":
        return "create";
      default:
        if (isMultiTrip) {
          return "multi";
        } else {
          return "single";
        }
    }
  }
  if (isMultiTrip) {
    return "multi";
  } else {
    return "single";
  }
}
