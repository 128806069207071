import { PropsWithChildren, ReactNode } from "react";
import { border_radius } from "src/design-system/tokens/border";
import styled, { CSSProperties } from "styled-components";
import { ClickAwayListener } from "../../components/ClickAwayListener/ClickAwayListener";
import { Dropdown, DropdownAnchor } from "../../components/Dropdown/Dropdown";
import { Icon } from "../../components/Icon/Icon";
import { ChevronDown } from "../../svg/ChevronDown";
import { fontWeight, spacing } from "../../theme";
import { useTheme } from "../../utils/hooks/useTheme";

type DropdownTypes = {
  text: ReactNode;
  label?: string;
  hideChevron?: boolean;
  borderRadius?: keyof typeof border_radius;
  $padding?: CSSProperties["padding"];
  anchor: DropdownAnchor;
  isDropdownOpen: boolean;
  handleClickButton: () => void;
  handleClickOffDropdown: () => void;
};

export function NavDropdown(props: PropsWithChildren<DropdownTypes>) {
  const theme = useTheme();

  return (
    <DropdownWrapper>
      <DropdownButton
        aria-label={props.label}
        $padding={props.$padding ?? spacing.lg}
        onClick={props.handleClickButton}
      >
        {props.text}
        {!props.hideChevron && (
          <StyledChevronIcon size="sm" pointUp={props.isDropdownOpen}>
            <ChevronDown tint={theme.topNav.iconTint} />
          </StyledChevronIcon>
        )}
      </DropdownButton>
      {props.isDropdownOpen && (
        <ClickAwayListener onClickAway={props.handleClickOffDropdown}>
          <StyledDropdown
            borderRadius={props.borderRadius}
            anchor={props.anchor}
          >
            {props.children}
          </StyledDropdown>
        </ClickAwayListener>
      )}
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownButton = styled.button<{ $padding: CSSProperties["padding"] }>`
  color: ${(props) => props.theme.topNav.text};
  font-weight: ${fontWeight.medium};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${(props) => props.$padding};

  &:hover {
    color: ${(props) => props.theme.topNav.textHover};
  }
`;

const StyledChevronIcon = styled(Icon)<{
  pointUp?: boolean;
}>`
  margin-left: ${spacing.md};

  ${(props) => {
    if (props.pointUp) {
      return "transform: rotate(180deg);";
    }
  }}

  ${DropdownButton}:hover & {
    svg path {
      stroke: ${(props) => props.theme.topNav.textHover};
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 270px;
`;
