import { useEffect, useRef } from "react";
import { ScreenLocations } from "src/analytics/getLocations/getLocations";
import { Screen } from "src/analytics/generateScreenComponentsRendered/Screen";
import { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import { SearchResponse } from "../../api/SearchResponse";
import { sendAnalyticsPageView } from "../../analytics/sendAnalyticsPageView";
import { GaPageConfig } from "../../analytics/configureAnalytics";
import { CarrierViewModel } from "../../domain/SegmentScreen/CarrierSection/carrierAdapter";
import { Mode } from "../types/mode";

// This will send one Page View event to analytics per search. It will wait to send the
// Page View until we have a search response. We must wait for the search response
// so that we have custom dimensions available. We need the custom dimensions so
// they aren't wiped out from the analytics configs when we reset it with the updated page
// information for the Page View event (see the `sendAnalyticsPageView` method).
export function useAnalyticsPageView(
  pageConfig: GaPageConfig,
  searchResponse?: SearchResponse,
  screen?: Screen,
  modes?: Mode[],
  components?: ScreenComponent[],
  locations?: ScreenLocations,
  levelCarriers?: CarrierViewModel[]
) {
  const hasSearchResponse = !!searchResponse;

  // Set initial 'current' values of the refs.
  const pageConfigRef = useRef(pageConfig);
  const customDimensionsRef = useRef(
    searchResponse?.analytics?.custom_dimensions
  );
  const screenRef = useRef(screen);
  const modesRef = useRef(modes);
  const componentsRef = useRef(components);
  const locationsRef = useRef(locations);

  // Update 'current' values of the refs. We can't rely on the lines above because
  // those only set the initial value. If we're in a subsequent call to this hook, then
  // updating the value like this is necessary.
  useEffect(() => {
    pageConfigRef.current = pageConfig;
  }, [pageConfig]);
  useEffect(() => {
    screenRef.current = screen;
  }, [screen]);
  useEffect(() => {
    modesRef.current = modes;
  }, [modes]);
  useEffect(() => {
    componentsRef.current = components;
  }, [components]);
  useEffect(() => {
    locationsRef.current = locations;
  }, [locations]);

  // We update custom_dimensions on route and segment panes to reflect the
  // carriers offered to the user at these levels. We retain the original
  // searchResponses custom_dimensions object to preserve the searchResponses
  // carrier data to send to analytics on a search pane view.
  useEffect(() => {
    const customDimensionsOriginal =
      searchResponse?.analytics?.custom_dimensions;
    const customDimensionsCopy = customDimensionsOriginal
      ? { ...customDimensionsOriginal }
      : undefined;

    if (levelCarriers && customDimensionsCopy) {
      // Construct string of carrier codes offered at this level.
      // Target dimension 35 for carrier details.
      customDimensionsCopy["dimension35"] = levelCarriers
        .map((carrier) => carrier.code)
        .join("|");
    }

    customDimensionsRef.current = levelCarriers
      ? customDimensionsCopy
      : customDimensionsOriginal;
  }, [searchResponse?.analytics?.custom_dimensions, levelCarriers]);

  // This effect is designed to only send one Page View per search.
  // The `hasSearchResponse` dependency will ensure that the effect is rerun only once after a
  // searchResponse has loaded.
  // The `pageConfig` and `customDimensions` info is stored in a ref because we don't
  // want the hook to rerun if they somehow get reset.
  useEffect(() => {
    if (hasSearchResponse) {
      sendAnalyticsPageView(
        pageConfigRef.current,
        customDimensionsRef.current,
        screenRef.current,
        modesRef.current,
        componentsRef.current,
        locationsRef.current
      );
    }
  }, [hasSearchResponse]);
}
