import { addBreadcrumb } from "@sentry/react";

let isReloadCalled = false; // Note: Will get reset to false by the page refreshing

const REFRESH_STORAGE_KEY = "lastRefresh";
const MIN_REFRESH_INTERVAL = 300000; // 5 minutes

function getTimeSinceLastRefresh() {
  const lastRefresh = sessionStorage.getItem(REFRESH_STORAGE_KEY);

  return lastRefresh === null ? null : Date.now() - Number(lastRefresh);
}

function canRefresh() {
  const time = getTimeSinceLastRefresh();

  return time === null || time > MIN_REFRESH_INTERVAL;
}

function isRefreshing() {
  return isReloadCalled;
}

export function refreshOnPreloadErrors() {
  // To handle outdated assets due to new deployments during open sessions this module listens for 'vite:preloadError' events.
  // It checks the time of the last refresh from the session storage. if > hour it reloads the page.
  window.addEventListener("vite:preloadError", (event) => {
    const error = event.payload;

    if (isRefreshing()) {
      addBreadcrumb({
        category: "chunks",
        message: "vite:preloadError - already reloading",
        data: { error },
      });
      return;
    } else if (canRefresh()) {
      addBreadcrumb({
        category: "chunks",
        message: "vite:preloadError - starting reload",
        data: { error },
      });

      sessionStorage.setItem(REFRESH_STORAGE_KEY, String(Date.now()));
      isReloadCalled = true;

      // Refresh the page with a nonce to bypass the cache
      const url = new URL(window.location.href);
      const timeNonce = Date.now().toString(36); // Convert to base36 to make it shorter
      url.searchParams.set("refresh", timeNonce);
      window.location.href = url.toString();
    } else {
      const timeSinceLastRefresh = getTimeSinceLastRefresh();
      addBreadcrumb({
        category: "chunks",
        message: "vite:preloadError - out of reloads",
        data: { error, timeSinceLastRefresh },
      });
    }
  });
}

export function isImportError(error: any) {
  return (
    !!error.message &&
    (error.message.includes("Failed to fetch dynamically imported module") || // Chrome
      error.message.includes("Importing a module script failed") || // Safari
      error.message.includes("error loading dynamically imported module")) // Firefox
  );
}

export function isHandledImportError(error: any) {
  return isImportError(error) && isRefreshing();
}
