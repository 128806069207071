import { border_radius } from "src/design-system/tokens/border";
import { color, spacing } from "src/theme";
import { largeDesktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";

const largeDesktopMinWidth = 1200;

export const NavButton = styled.li<{
  noLeftPadding?: boolean;
  $isActive?: boolean;
  $small?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;

  a {
    padding: ${spacing.lg} 20px;
    border-radius: ${border_radius.rounded_lg};
    color: ${(props) => (props.$isActive ? color.cod : "#707070")};
    text-decoration: ${(props) => (props.$isActive ? "underline" : undefined)};

    ${({ $small }) => {
      if ($small) {
        return css`
          padding: ${spacing.md};
          margin: 0 ${spacing.sm};

          ${largeDesktopLayout} {
            margin: 0 ${spacing.xs};
            padding: ${spacing.lg} 20px;
          }
        `;
      }
    }}

    &:hover {
      background-color: ${color.n30};
      color: ${color.cod};
    }
  }

  ${(props) => {
    if (props.noLeftPadding) {
      return css`
        padding-left: 0;
      `;
    } else {
      return css`
        @media screen and (min-width: ${largeDesktopMinWidth}px) {
          padding: 0 0 0 ${spacing.md};
        }
      `;
    }
  }}
`;
