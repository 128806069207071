import { Skeleton } from "src/components/Skeleton/Skeleton";
import { border_radius } from "src/design-system/tokens/border";
import { color, spacing } from "src/theme";

import styled from "styled-components";

export function TripSearchResultSkeleton() {
  return (
    <Container>
      <Skeleton height="100%" />
    </Container>
  );
}

const Container = styled.div`
  height: 110px;
  background-color: ${color.white};
  border-radius: ${border_radius.rounded_md};
  box-shadow: 0 ${spacing.xs} ${spacing.md} 0px rgba(0, 0, 0, 0.1);
  margin-bottom: ${spacing.md};
`;
