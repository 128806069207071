import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import styled from "styled-components";

export function DottedLine() {
  return (
    <DottedLineDiv>
      <DottedLineSvg width="2" height="40">
        <line
          x1="1"
          y1="0"
          x2="1"
          y2="44"
          stroke={color.icon.secondary}
          strokeDashoffset="1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="0, 6"
        />
      </DottedLineSvg>
    </DottedLineDiv>
  );
}

const DottedLineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
`;
const DottedLineSvg = styled.svg`
  position: absolute;
`;
