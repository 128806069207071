import styled, { css } from "styled-components";
import { desktopLayout } from "src/utils/hooks/useLayout";
import { SegmentCarHirePromoDriveVariant } from "src/domain/SegmentScreen/SegmentCarHirePromoDriveVariant/SegmentCarHirePromoDriveVariant";
import { SearchResponse } from "src/api/SearchResponse";
import { useFeature } from "src/feature/useFeature";
import { Timeline } from "../../Timeline";
import { OriginCell } from "../cell/OriginCell";
import {
  color,
  fontSize,
  fontWeight,
  screenMinWidth,
  spacing,
} from "../../../../theme";

type ConditionalDriveSegmentProps =
  | {
      isDriveSegment: boolean;
      segmentIndex: number;
      searchResponse: SearchResponse;
    }
  | {
      isDriveSegment?: false;
      segmentIndex?: never;
      searchResponse?: never;
    };

type Props = {
  cell: OriginCell;
  className?: string;
  isOriginTimelineHidden?: boolean;
} & ConditionalDriveSegmentProps;

export function TroniconTimelineOrigin(props: Props) {
  const isTripBreakpointsFeature = useFeature("TripBreakpoints");

  return (
    <Wrapper isDriveSegment={props.isDriveSegment} className={props.className}>
      <Timeline
        type="origin"
        transitMode={props.cell.transitMode}
        isOriginTimelineHidden={props.isOriginTimelineHidden}
      />
      <Content $isTripBreakpoint={isTripBreakpointsFeature}>
        <LeftAligned>
          <OriginName
            shouldBold={props.cell.isFirstSegment || props.cell.isStandalone}
          >
            {props.cell.placeName}
          </OriginName>
          {props.cell.tripOriginName && (
            <AlternativeOriginName>
              {props.cell.tripOriginName}
            </AlternativeOriginName>
          )}
        </LeftAligned>
        {props.isDriveSegment && (
          <SegmentCarHirePromoDriveVariant
            location={props.cell.placeName}
            segmentIndex={props.segmentIndex}
            searchResponse={props.searchResponse}
            transitMode={props.cell.transitMode}
          />
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isDriveSegment?: boolean }>`
  display: flex;
  align-items: ${({ isDriveSegment }) =>
    isDriveSegment ? "flex-start" : "center"};
  padding-left: 16px;
  margin-bottom: 6px;
  margin-top: 6px;

  ${desktopLayout} {
    align-items: center;
  }
`;
const Content = styled.div<{ $isTripBreakpoint: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing.md};
  padding-left: ${spacing.xl};
  ${({ $isTripBreakpoint }) =>
    $isTripBreakpoint
      ? css`
          ${screenMinWidth.md} {
            flex-direction: row;
          }
        `
      : css`
          ${desktopLayout} {
            flex-direction: row;
          }
        `}
`;

const LeftAligned = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: auto;
`;

const OriginName = styled.span<{ shouldBold: boolean }>`
  font-size: ${fontSize.h5};
  font-weight: ${(props) =>
    props.shouldBold ? fontWeight.medium : fontWeight.normal};
`;

const AlternativeOriginName = styled.span`
  font-size: ${fontSize.h6};
  color: ${color.n90};
`;
