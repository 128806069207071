import { SearchResponse } from "../../api/SearchResponse";
import {
  transitModeFromSegment,
  transitModeFromVehicleKind,
} from "../../utils/adapters/transitMode";
import { Mode } from "../../utils/types/mode";
import { placesFromSegment } from "../../utils/adapters/place";
import { SchedulesResponse } from "../../api/SchedulesResponse";
import { getInboundOrOutboundItineraries } from "../../utils/getInboundOrOutboundItineraries";
import { SelectedScheduleType } from "../../domain/SegmentScreen/ReturnFlowStateProvider";
import { ReturnStage } from "../../utils/hooks/useTypedLocation";
import { decodePath } from "./internal/decodePath";

export type MapSegment = {
  paths: google.maps.LatLngLiteral[][];
  places: google.maps.LatLngLiteral[];
  transitMode: Mode;
  isMajor?: boolean;
  blurred?: boolean;
  name?: string;
  number?: number;
};

export function mapSegmentsFromAllRoutes(
  searchResponse: SearchResponse
): MapSegment[] {
  return searchResponse.routes.reduce(
    (segments: MapSegment[], route, routeIndex) =>
      segments.concat(mapSegmentsFromRoute(searchResponse, routeIndex)),
    []
  );
}

export function mapSegmentsFromRoute(
  searchResponse: SearchResponse,
  routeIndex: number,
  focusedSegmentIndex?: number
): MapSegment[] {
  // Check if the route index is out of bounds.
  if (searchResponse.routes[routeIndex] === undefined) {
    return mapSegmentsWhenNoRoute(searchResponse);
  }
  return searchResponse.routes[routeIndex].segments.map((it, segmentIndex) => {
    const blurred =
      focusedSegmentIndex !== undefined && focusedSegmentIndex !== segmentIndex;
    return mapSegmentFromRouteOption(
      searchResponse,
      routeIndex,
      segmentIndex,
      0,
      blurred
    );
  });
}

export function mapSegmentFromRouteOption(
  searchResponse: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  optionIndex: number,
  blurred?: boolean
): MapSegment {
  const searchResponseSegmentIndex =
    searchResponse.routes[routeIndex].segments[segmentIndex];
  const segment = searchResponse.segments[searchResponseSegmentIndex];

  const searchResponseOptionIndex = segment.options[optionIndex];
  const option = searchResponse.options[searchResponseOptionIndex];

  const transitMode = transitModeFromSegment(
    searchResponse,
    routeIndex,
    segmentIndex
  );

  const hops = option.hops.map((hopIndex) => searchResponse.hops[hopIndex]);

  const paths = hops.map((hop) => {
    const pathIndex = searchResponse.lines[hop.line].path;
    const path = searchResponse.paths[pathIndex];

    return decodePath(path);
  });

  const places = placesFromSegment(
    searchResponse,
    searchResponseSegmentIndex
  ).map((place) => {
    return { lat: place.lat, lng: place.lng };
  });

  return {
    paths,
    places,
    transitMode,
    isMajor: segment.isMajor,
    blurred,
  };
}

export function mapSegmentsWhenNoRoute(
  searchResponse: SearchResponse
): MapSegment[] {
  const originPlace = searchResponse.places[0];
  const destinationPlace =
    searchResponse.places[searchResponse.places.length - 1];
  return [
    {
      places: [
        { lat: originPlace.lat, lng: originPlace.lng },
        { lat: destinationPlace.lat, lng: destinationPlace.lng },
      ],
      paths: [
        [
          { lat: originPlace.lat, lng: originPlace.lng },
          { lat: destinationPlace.lat, lng: destinationPlace.lng },
        ],
      ],
      transitMode: "unknown",
    },
  ];
}

export function mapSegmentsFromAllItineraries(
  schedulesResponse: SchedulesResponse,
  returnStage: ReturnStage,
  departureSchedule?: SelectedScheduleType
): MapSegment[] {
  const itinerary = getInboundOrOutboundItineraries(
    schedulesResponse,
    returnStage,
    departureSchedule
  );
  return itinerary.reduce(
    (segments: MapSegment[], itinerary, itineraryIndex) =>
      segments.concat(
        mapSegmentsFromItinerary(
          schedulesResponse,
          itineraryIndex,
          returnStage,
          departureSchedule
        )
      ),
    []
  );
}

export function mapSegmentsFromItinerary(
  schedulesResponse: SchedulesResponse,
  itineraryIndex: number,
  returnStage: ReturnStage,
  departingSchedule?: SelectedScheduleType
): MapSegment[] {
  const itinerary = getInboundOrOutboundItineraries(
    schedulesResponse,
    returnStage,
    departingSchedule
  )[itineraryIndex];
  // Early exit if itineraryIndex is out of range.
  if (!itinerary) return [];

  return itinerary.legs.reduce((acc: MapSegment[], it) => {
    const segments = mapSegmentsFromLeg(
      schedulesResponse,
      it,
      transitModeFromVehicleKind(itinerary.transitKind)
    );
    for (const segment of segments) {
      if (segment !== undefined) acc.push(segment);
    }
    return acc;
  }, []);
}

function mapSegmentsFromLeg(
  schedulesResponse: SchedulesResponse,
  legId: number,
  defaultTransitMode: Mode
): (MapSegment | undefined)[] {
  const leg = schedulesResponse.legs[legId];

  return leg.hops.map((hopIndex) =>
    mapSegmentFromHop(schedulesResponse, hopIndex, defaultTransitMode)
  );
}

function mapSegmentFromHop(
  schedulesResponse: SchedulesResponse,
  hopId: number,
  defaultTransitMode: Mode
): MapSegment | undefined {
  const hop = schedulesResponse.hops[hopId];
  const line = schedulesResponse.lines[hop.line];

  // The line will only have one path, but it could be undefined. We want this path to render
  // the polyline when a schedule is hovered - so if it's undefined, we'll make an early exit
  // and just use the path from the searchResponse.
  if (line.path === undefined) return;

  const path = schedulesResponse.paths[line.path];
  const paths = [decodePath(path)];

  const places = line.places.reduce((places: MapSegment["places"], it) => {
    const { lat, lng } = schedulesResponse.places[it];
    if (lat != null && lng != null) {
      places.push({ lat, lng });
    }
    return places;
  }, []);

  const vehicle =
    hop.vehicle !== undefined
      ? schedulesResponse.vehicles[hop.vehicle]
      : undefined;

  const transitMode = vehicle
    ? transitModeFromVehicleKind(vehicle.kind)
    : defaultTransitMode;

  return {
    paths,
    places,
    transitMode,
    blurred: false,
  };
}
