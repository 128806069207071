import { isDebugBySearchParams } from "src/utils/isDebugBySearchParams";
import { isContentTest, isPreview } from "../utils/host";

export const PossibleFeaturesMap = {
  Debug: [false, true],
  LogAnalyticsEventsToConsole: [false, true],
  FillAds: ["default", "never", "alwaysWithTestAds"],

  // Don't delete, required for content-test
  SkipRoutes: [false, true],
  SeeLessSchedulesFeature: [false, true],
  UseStaticConnectedSchedules: [
    "never",
    "FullyScheduleableNonBookableRoutes",
    "FullyBookableRoutesFallback",
    "FirstOrLastWalkingSegmentRoutes",
  ],
  SkipNonBookableRoutes: [false, true],
  ForceUseLiveScheduleFallback: [false, true],
  UseDarkMode: [false, true],
  GoogleCustomButton: [false, true],
  ShortNavFeature: [false, true],
  KayakInlineAds: [false, true],
  DatesInTripPlanner: [false, true],
  SchedulesFeedbackButton: [false, true],
  GAMConsentManagement: [false, true],
  JayrideTransfersPromos: [false, true],
  StrikethroughDeals: ["baseline", "red", "green"],
  USStateConsentManagement: [false, true],
  ServiceSummaryImprovements: [false, true],
  DelayPrebidUntilConsentReady: [false, true],
  DesktopInlineSerpAd90: [false, true],
  HExDefaultSortOrder: ["default", "priceLow", "reviewHigh"],
  CmpDefaultConsent: [false, true],
  StackedNavigationAccom: [false, true],
  TripsV2OnMobile: [false, true],
  TimeOfDayFilter: [false, true],
  HExScrollNudge: [false, true],
  PreDestinationPages: ["baseline", "card", "transitInfo"],
  HExRefineSearchKind: ["baseline", "suburb", "attraction"],
  HExRefineSearchPosition: ["baseline", "top", "mid"],
  ReactQueryDevTools: [false, true],
  DestinationPagesQA: [false, true],
  FreelancerQA: ["default", "login", "hide"],
  MobileInlineSerpAd: [false, true],
  NonEngTranslationsQA: [false, true],
  InlineGAMSegmentAd: [false, true],
  MobileInlineSerpAdQA: [false, true],
  DataOpsQA: [false, true],
  HExCTACopyPortuguese: [
    "Baseline",
    "ViewOffer",
    "CheckOutTheHotel",
    "SeeTheHotel",
    "SeeMore",
    "SeeDetails",
    "ViewOptions",
  ],
  HExCTACopyFrench: [
    "Baseline",
    "MoreInfo",
    "DisplayDeal",
    "SeeHotel",
    "SeeStay",
    "ViewNow",
    "ViewTheOptions",
  ],
  HExCTACopySpanish: [
    "Baseline",
    "ViewDeal",
    "FindDeal",
    "SeeHotel",
    "SeeDetails",
    "ViewNow",
  ],
  HExCTACopyGerman: ["Baseline", "FindDeal", "ViewMore", "SeeDetails", "View"],
  IslandRoutingQA: [false, true],
  MobileTripsV2QA: [false, true],
  HExIsExpedia: [false, true],
  TripBreakpoints: [false, true],
} as const;

// Note: We do not include the 'test' environment as isDevelopment as all features here are presentational only
const isDevelopment = import.meta.env.MODE === "development";

export const defaultFeatureConfig: FeatureConfig = {
  Debug: isDevelopment || isPreview(),
  LogAnalyticsEventsToConsole:
    isDevelopment || isPreview() || isDebugBySearchParams(),
  FillAds: isDevelopment || isPreview() ? "alwaysWithTestAds" : "default",
  SkipRoutes: !isContentTest(),
  SeeLessSchedulesFeature: false,
  UseStaticConnectedSchedules: "FirstOrLastWalkingSegmentRoutes",
  SkipNonBookableRoutes: true,
  ForceUseLiveScheduleFallback: false,
  UseDarkMode: false,
  // For further information on this feature, see: https://rome2rio.atlassian.net/wiki/spaces/~630c49a1d8850343ef57e7e9/pages/2709291041/Google+Sign+In+for+Explore+GAPI+vs+GSI
  GoogleCustomButton: false,
  ShortNavFeature: false,
  KayakInlineAds: true,
  DatesInTripPlanner: false,
  SchedulesFeedbackButton: false,
  GAMConsentManagement: false,
  JayrideTransfersPromos: false,
  StrikethroughDeals: "baseline",
  USStateConsentManagement: false,
  ServiceSummaryImprovements: false,
  DelayPrebidUntilConsentReady: !isDevelopment && !isPreview(),
  DesktopInlineSerpAd90: false,
  HExDefaultSortOrder: "default",
  CmpDefaultConsent: isDevelopment || isPreview(),
  StackedNavigationAccom: false,
  TripsV2OnMobile: false,
  TimeOfDayFilter: false,
  HExScrollNudge: false,
  PreDestinationPages: "baseline",
  HExRefineSearchKind: "baseline",
  HExRefineSearchPosition: "baseline",
  ReactQueryDevTools: false,
  DestinationPagesQA: false,
  FreelancerQA: "default",
  MobileInlineSerpAd: false,
  NonEngTranslationsQA: false,
  InlineGAMSegmentAd: false,
  MobileInlineSerpAdQA: false,
  DataOpsQA: false,
  HExCTACopyPortuguese: "Baseline",
  HExCTACopyFrench: "Baseline",
  HExCTACopySpanish: "Baseline",
  HExCTACopyGerman: "Baseline",
  IslandRoutingQA: false,
  MobileTripsV2QA: false,
  HExIsExpedia: false,
  TripBreakpoints: false,
};

export type FeatureName = keyof typeof PossibleFeaturesMap;

export type FeatureValue<F extends FeatureName> =
  (typeof PossibleFeaturesMap)[F][number];

export type FeatureConfig = {
  [F in FeatureName]: FeatureValue<F>;
};
