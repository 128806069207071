import { useIntl } from "react-intl";

import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import { GeocodedPlace } from "src/PrefetchData";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { useFeature } from "src/feature/useFeature";
import { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import messages from "../TripHotelMapPin/TripHotelMapPin.messages";
import { PanePromoButton } from "../PanePromoButton/PanePromoButton";

export function HotelsPromo({
  location,
  eventCategory = "SearchResults",
}: {
  location: GeocodedPlace;
  eventCategory?: AnalyticsEventCategory;
}) {
  const hexIsExpedia = useFeature("HExIsExpedia");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const intl = useIntl();
  const { navigateToHotels } = useNavigateToHotelsPage();

  const promoText = intl.formatMessage(messages.hotelsIn, {
    location: location.shortName,
  });
  const tagline = intl.formatMessage(messages.compare);

  return (
    <PanePromoButton
      onClick={() => {
        sendAnalyticsInteractionEvent(
          eventCategory,
          "Click:HotelPromoSingleDest"
        );

        if (hexIsExpedia && handleExpediaExit) {
          handleExpediaExit({
            lat: location?.lat,
            lng: location?.lng,
            canonicalName: location?.canonicalName,
          });
        } else {
          navigateToHotels();
        }
      }}
      promoText={promoText}
      tagline={tagline}
    >
      <HotelAlternative tint="cod" />
    </PanePromoButton>
  );
}
