import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSearch from "src/utils/hooks/useSearch";
import { useFeature } from "src/feature/useFeature";
import { useNavigate } from "react-router";
import {
  ReturnStage,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { useTripOrTransportIndexes } from "src/utils/hooks/useTripOrTransportIndexes";
import { BookingDetails, Itinerary } from "../../api/SchedulesResponse";
import { Mode } from "../../utils/types/mode";
import { useTripSearchResponse } from "../TripPlanner/hooks/useTripSearchResponse";
import { useTravelSearchSettings } from "./TravelSearchSettingsProvider";
import { createSegmentScreenViewModel } from "./createSegmentScreenViewModel";
import { ScheduleItem } from "./DynamicSchedules/createScheduleItemList";

export const defaultReturnFlowState: ReturnFlowStateType = {
  isReturn: false,
};

export type SelectedScheduleType = {
  itinerary?: Itinerary;
  price?: string;
  summary?: ScheduleItem;
  scheduleIndex?: number;
};

export type DualBookingDetails = {
  outboundBookingDetails: BookingDetails;
  inboundBookingDetails: BookingDetails;
};

type RedirectDetailsType = {
  bookingDetails?: {
    singleBookingDetails?: BookingDetails;
    dualBookingDetails?: DualBookingDetails;
  };
};

export type ReturnFlowStateType = {
  isReturn: boolean;
  departingSchedule?: SelectedScheduleType;
  returnSchedule?: SelectedScheduleType;
  redirectDetails?: RedirectDetailsType;
};

export type ReturnFlowState = {
  returnFlowState: ReturnFlowStateType;
  setReturnFlowState: Dispatch<SetStateAction<ReturnFlowStateType>>;
  isReturnsSupported: boolean;
};

export const ReturnFlowStateContext = createContext<ReturnFlowState>({
  returnFlowState: defaultReturnFlowState,
  setReturnFlowState: () => {},
  isReturnsSupported: false,
});

export function useReturnFlowState() {
  const context = useContext(ReturnFlowStateContext);
  if (!context) {
    throw new Error(
      `useReturnFlowState must be used within ReturnFlowStateProvider`
    );
  }
  return context;
}

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function ReturnFlowStateProvider(props: PropsWithChildren<Props>) {
  const isTripsScreen = useIsTripScreen();
  const { tripSearchResponse } = useTripSearchResponse();
  const { searchResponse: searchFromQuery } = useSearch();
  const searchResponse = isTripsScreen ? tripSearchResponse : searchFromQuery;
  const { dateTimeObject } = useTravelSearchSettings();

  // As we have two route canonicals in the URL (map/a/b) and #trips/a/b we need
  // to make sure we check the correct one based on if we're in the trip planner
  const { routeIndex, routeSegmentIndex } = useTripOrTransportIndexes();

  const navigate = useNavigate();
  const location = useTypedLocation();
  const staticConnectedSchedulesFeature = useFeature(
    "UseStaticConnectedSchedules"
  );

  const [returnFlowState, setReturnFlowState] = useState<ReturnFlowStateType>({
    isReturn: false,
  });

  const isReturnsSupported = useMemo(() => {
    if (
      searchResponse === undefined ||
      routeIndex === undefined ||
      searchResponse.routes[routeIndex] === undefined
    ) {
      return false;
    }
    // TODO: We don't need all of this function, just a bit of it. We need to refactor our
    // scheduleResponse usage to be more granular.
    const viewModel = createSegmentScreenViewModel(
      searchResponse,
      routeIndex,
      routeSegmentIndex,
      undefined,
      staticConnectedSchedulesFeature
    );

    return (
      isReturnsEligibleMode(viewModel.transitMode) &&
      viewModel.capabilities.isBookable
    );
  }, [
    routeIndex,
    routeSegmentIndex,
    searchResponse,
    staticConnectedSchedulesFeature,
  ]);

  const providerValue = useMemo(
    () => ({
      returnFlowState: {
        ...returnFlowState,
        isReturn: isReturnsSupported && returnFlowState.isReturn,
      },
      setReturnFlowState,
      isReturnsSupported,
    }),
    [returnFlowState, isReturnsSupported]
  );

  // Ensure that we can never have isReturn false whilst a returnDate is present,
  // and that if isReturnSupported is false, isReturn is also false
  useEffect(() => {
    if (!returnFlowState.isReturn && dateTimeObject.returnDate) {
      setReturnFlowState((prevState) => ({ ...prevState, isReturn: true }));
    }
  }, [returnFlowState, dateTimeObject, providerValue, isReturnsSupported]);

  // Another hack for now - reset the returnFlowLocation if we're missing selected schedules.
  // Note: We don't need to move forwards - the user can do that if they want
  // TODO: This should be moved into a top-level component handling all schedule issues (e.g. TripSummary and SegmentScreen)
  useEffect(() => {
    // If we expect a departure schedule to be provided, and none is
    if (
      props.returnsFlowLocation &&
      ["return", "summary"].includes(props.returnsFlowLocation) &&
      !returnFlowState.departingSchedule
    ) {
      navigateToNewState(
        navigate,
        { returnsFlowLocation: "departing" },
        location,
        true
      );
    }
    // If we expect a return schedule to be provided, and none is
    else if (
      props.returnsFlowLocation === "summary" &&
      returnFlowState.isReturn &&
      !returnFlowState.returnSchedule
    ) {
      navigateToNewState(
        navigate,
        { returnsFlowLocation: "return" },
        location,
        true
      );
    }
  }, [returnFlowState, props.returnsFlowLocation, location, navigate]);

  return (
    <ReturnFlowStateContext.Provider value={providerValue}>
      {props.children}
    </ReturnFlowStateContext.Provider>
  );
}

export function isReturnsEligibleMode(transitMode: Mode) {
  return (
    transitMode !== "helicopter" &&
    transitMode !== "towncar" &&
    transitMode !== "taxi" &&
    transitMode !== "shuttle" &&
    transitMode !== "walk"
  );
}
