import { SearchResponse } from "../../api/SearchResponse";
import { useRouteParams } from "../useRouteParams";
import { Geocoded } from "../../PrefetchData";
import { useGeocodeCanonical } from "./useGeocode";
import { useGetRoutes } from "./useGetRoutes";
import { useSearchOverride } from "./SearchOverrideProvider";

// The useSearch hook performs a search request for the origin and destination
// canonical in the browser URL. It's safe to use multiple times and it will only
// make the request once for each canonical pair, regardless of the number of
// times the hook is used.
function useSearch(): SearchState {
  const { originCanonical, destinationCanonical } = useRouteParams();
  const originResult = useGeocodeCanonical(originCanonical);
  const destinationResult = useGeocodeCanonical(destinationCanonical);
  const searchResult = useGetRoutes(originResult.data, destinationResult.data);

  // If the search is overridden, return it and ignore the API, this is only
  // used when SearchOverrideProvider is in the react tree.
  const overriddenSearch = useSearchOverride();
  const overriddenSearchResponse = overriddenSearch?.searchResponse;
  if (overriddenSearchResponse) {
    return {
      isLoading: false,
      searchResponse: overriddenSearchResponse,
      origin:
        overriddenSearch.origin ??
        originResult.data ??
        createDefaultPlaceFromCanonical(originCanonical),
      destination:
        overriddenSearch.destination ??
        destinationResult.data ??
        createDefaultPlaceFromCanonical(destinationCanonical),
    };
  }

  // If geocode doesn't return a place, then the search has errored since no
  // search request can be performed
  const noGeocodeOriginError =
    originResult.status === "success" && !originResult.data
      ? new Error(`Geocode request for ${originCanonical} returned no places`)
      : undefined;
  const noGeocodeDestinationError =
    destinationResult.status === "success" && !destinationResult.data
      ? new Error(
          `Geocode request for ${destinationCanonical} returned no places`
        )
      : undefined;

  // We always want to return a GeocoderResult, even if it isn't 100%
  // correct, because we want to render the places in the UI as early as
  // possible.
  return {
    searchResponse: searchResult.data,
    origin:
      originResult.data ?? createDefaultPlaceFromCanonical(originCanonical),
    destination:
      destinationResult.data ??
      createDefaultPlaceFromCanonical(destinationCanonical),
    error:
      searchResult.error ??
      originResult.error ??
      destinationResult.error ??
      noGeocodeOriginError ??
      noGeocodeDestinationError,
    isLoading: searchResult.status === "pending",
    fromCache:
      // This hook can be used multiple times within a screen render so we need
      // an appropriate amount of time to pass before we consider it cached.
      !!(Date.now() - searchResult.dataUpdatedAt > 1000),
  };
}

// Default places are used in the UI before we have geocoded the canonicals,
// this lets us render the canonical names briefly so the UI isn't empty for too
// long.
const createDefaultPlaceFromCanonical = (
  canonical?: string
): SearchPlace | undefined => {
  if (!canonical) {
    return undefined;
  }
  return {
    shortName: canonical,
    longName: canonical,
    canonicalName: canonical,
  };
};

// Default places are used in the UI before we've geocded the canonicals. Because of this we
// can't just use the GeocoderResult type and instead have to create our own that
// has optional values for the information we can't get at all before a geocode.
export type SearchPlace = {
  shortName: string;
  longName?: string;
  canonicalName: string;
  lat?: number;
  lng?: number;
  googlePlaceId?: string;
  kind?: string;
};

export type SearchState = {
  isLoading: boolean;
  error?: Error | unknown;
  searchResponse?: SearchResponse;
  origin?: Geocoded | SearchPlace;
  destination?: Geocoded | SearchPlace;
  fromCache?: boolean;
};

export default useSearch;
