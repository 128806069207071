import { useIntl } from "react-intl";
import { Icon } from "src/components/Icon/Icon";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { border_radius } from "src/design-system/tokens/border";
import { default as colorDS } from "src/design-system/tokens/color";
import { Suitcase } from "src/svg/Suitcase";
import { Trash } from "src/svg/Trash";
import { color, fontSize, fontWeight, letterSpacing, spacing } from "src/theme";

import { useId, useRef, useState } from "react";
import { ButtonBase } from "src/components/Button/ButtonBase";
import Tooltip from "src/components/Tooltip/Tooltip";
import { duration } from "src/design-system/tokens/animation";
import { A11yOutline } from "src/utils/accessibility";
import {
  desktopLayout,
  mobileLayout,
  useLayout,
} from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import messages from "./MyTripCardContent.messages";

type MyTripCardCallbacks = {
  onClickDelete?: () => void;
  onClick?: () => void;
};

export type MyTripCardContentProps = {
  title: string;
  numDestinations: number;
  isCurrent?: boolean;
  isLoggedOut?: boolean;
  variant?: "grey" | "white";
};

export function MyTripCardContent(
  props: MyTripCardContentProps & MyTripCardCallbacks
) {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";
  const id = useId();

  const [isDeleting, setIsDeleting] = useState(false);
  const confirmFocusRef = useRef<HTMLButtonElement>(null);

  function onClickRemoveButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    props.onClickDelete?.();
  }

  function clickHandler(event: React.MouseEvent) {
    if (!(event.target as HTMLElement).hasAttribute("data-delete")) {
      if (isDeleting) {
        setIsDeleting(false);
        return;
      }
      props.onClick?.();
    } else {
      if (isMobile) {
        setIsDeleting(true);
        confirmFocusRef.current?.focus();
      } else {
        props.onClickDelete?.();
      }
    }
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const focusableElements = document.getElementsByClassName(
        "myTripCard"
      ) as HTMLCollectionOf<HTMLElement>;
      if (!focusableElements) {
        return;
      }
      const currentIndex = Array.from(focusableElements).findIndex(
        (element) => element === document.activeElement
      );
      if (currentIndex === -1) {
        return;
      }
      if (event.key === "ArrowUp" && currentIndex > 0) {
        focusableElements[currentIndex - 1].focus();
      }
      if (
        event.key === "ArrowDown" &&
        currentIndex < focusableElements.length - 1
      ) {
        focusableElements[currentIndex + 1].focus();
      }
    }
    if (event.key === " " || event.key === "Enter") {
      props.onClick?.();
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setIsDeleting(false)}>
      <MyTripCardWrapper
        title={props.title}
        onClick={clickHandler}
        isLoggedOut={props.isLoggedOut}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        className="myTripCard"
        data-testid="myTripCard"
        variant={props.variant}
      >
        <TripCardInner>
          <IconWrapper>
            <Icon size="sm">
              <Suitcase tint={props.isLoggedOut ? "n50" : "cod"} />
            </Icon>
          </IconWrapper>
          <DetailsWrapper>
            <Title isLoggedOut={props.isLoggedOut}>{props.title}</Title>
            <Destinations isLoggedOut={props.isLoggedOut}>
              {intl.formatMessage(messages.destinations, {
                numDestinations: props.numDestinations,
              })}
            </Destinations>
          </DetailsWrapper>
          {!props.isLoggedOut && !props.isCurrent && (
            <Tooltip
              disableAnimation
              id={id}
              tooltipContent={
                <DeleteTooltipContent>
                  {intl.formatMessage(messages.deleteTrip)}
                </DeleteTooltipContent>
              }
            >
              <DeleteButton
                data-delete
                onKeyDown={(e) => e.stopPropagation()}
                title={intl.formatMessage(messages.deleteTrip)}
              >
                <Icon size="sm">
                  <Trash tint="cod" />
                </Icon>
              </DeleteButton>
            </Tooltip>
          )}
          <ConfirmDeleteButton
            ref={confirmFocusRef}
            $isDelete={isDeleting}
            onClick={onClickRemoveButton}
            disabled={!isDeleting}
            aria-hidden={!isDeleting}
            title={intl.formatMessage(messages.confirmDelete)}
            aria-label={intl.formatMessage(messages.confirmDeleteMessage, {
              element: props.title,
            })}
          >
            {intl.formatMessage(messages.delete)}
          </ConfirmDeleteButton>
        </TripCardInner>
        {props.isCurrent && !props.isLoggedOut && (
          <Pill as="label">{intl.formatMessage(messages.currentTrip)}</Pill>
        )}
      </MyTripCardWrapper>
    </ClickAwayListener>
  );
}

export function MyTripCardSkeleton() {
  return (
    <MyTripCardWrapper>
      <TripCardInner>
        <IconWrapper>
          <Icon size="sm">
            <Suitcase tint="n50" />
          </Icon>
        </IconWrapper>
        <DetailsWrapper>
          <Skeleton width="100px" height="20px" margin={`0 0 ${spacing.sm}`} />
          <Skeleton width="40px" height="10px" />
        </DetailsWrapper>
      </TripCardInner>
    </MyTripCardWrapper>
  );
}

const ConfirmDeleteButton = styled(ButtonBase)<{ $isDelete: boolean }>`
  background-color: ${color.high};
  overflow: hidden;

  padding-left: ${({ $isDelete }) => ($isDelete ? spacing.xl : "0px")};
  padding-right: ${({ $isDelete }) => ($isDelete ? spacing.xl : "0px")};

  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h6};
  color: ${color.white};

  border-top-right-radius: ${border_radius.rounded_md};
  border-bottom-right-radius: ${border_radius.rounded_md};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: ${({ $isDelete }) => ($isDelete ? "72px" : "0px")};
  transition: width ${duration.md}ms ease-in-out,
    padding-left ${duration.md}ms ease-in-out,
    padding-right ${duration.md}ms ease-in-out;
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;

const DeleteButton = styled.button`
  border-radius: ${border_radius.rounded_md};
  border: none;
  opacity: 0;
  width: 0;
  cursor: pointer;
  ${A11yOutline}

  > * {
    // We want all click events to happen directly on the button component.
    pointer-events: none;
  }

  &:focus-visible {
    width: unset;
    padding: ${spacing.lg};
    opacity: 1;
    svg {
      path {
        fill: ${color.red};
        stroke: ${color.red};
      }
    }
  }

  &:hover {
    background-color: ${color.white};
    svg {
      path {
        fill: ${color.red};
        stroke: ${color.red};
      }
    }
  }

  ${mobileLayout} {
    opacity: 1;
    width: unset;
    padding: ${spacing.lg};
    svg {
      path: {
        fill: ${colorDS.icon.icon};
        stroke: ${colorDS.icon.icon};
      }
    }
  }
`;

const DeleteTooltipContent = styled.span`
  display: block;
  font-size: ${fontSize.sm};
  padding: ${spacing.sm} ${spacing.md};
`;

const MyTripCardWrapper = styled.li<{
  isLoggedOut?: boolean;
  variant?: "grey" | "white";
}>`
  list-style: none;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${border_radius.rounded_md};
  padding: ${spacing.lg} ${spacing.lg} ${spacing.lg} 0;
  ${A11yOutline};
  outline-offset: -2px;

  ${mobileLayout} {
    background-color: white;
  }

  ${desktopLayout} {
    &:hover {
      background-color: ${(props) =>
        props.isLoggedOut ? "transparent" : "#f2f2f2"};

      cursor: ${(props) => (props.isLoggedOut ? "default" : "pointer")};
      ${DeleteButton} {
        width: unset;
        padding: ${spacing.lg};
        opacity: 1;
      }
    }
  }
  &:focus-visible {
    ${DeleteButton} {
      width: unset;
      padding: ${spacing.lg};
      opacity: 1;
    }
  }

  ${mobileLayout} {
    border: 1px solid ${colorDS.border.border};
    margin-bottom: ${spacing.md};
    padding-left: ${spacing.lg};
  }
`;

const TripCardInner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  gap: ${spacing.lg};
`;

const Title = styled.h4<{ isLoggedOut?: boolean }>`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(props) => (props.isLoggedOut ? color.n50 : color.cod)};
`;

const IconWrapper = styled.div`
  border-radius: ${border_radius.rounded_md};
  padding: ${spacing.lg};
  background-color: #f2f2f2;
`;

const DetailsWrapper = styled.div`
  max-width: 100%;
  flex: 1;
`;

const Destinations = styled.div<{ isLoggedOut?: boolean }>`
  color: ${(props) => (props.isLoggedOut ? color.n40 : color.n90)};
  font-size: ${fontSize.md};
  margin-bottom: ${spacing.sm};
`;

const Pill = styled.span`
  margin-left: ${spacing.xxxxl};
  background-color: #e6f2ee;
  border-radius: ${border_radius.rounded_lg};
  color: #007c50;
  text-transform: uppercase;
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.medium};
  letter-spacing: ${letterSpacing.wider};
  padding: 5px; // ds is 5px :/
`;
