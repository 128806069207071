import { SearchResponse } from "src/api/SearchResponse";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { useFeature } from "src/feature/useFeature";
import { useLayout } from "src/utils/hooks/useLayout";
import { SearchCard } from "../SearchCard/SearchCard";
import {
  RouteTripCard,
  RouteTripCardProps,
} from "./RouteTripCard/RouteTripCard";
import {
  ScheduleOrSegmentTripCard,
  ScheduleOrSegmentTripCardProps,
} from "./ScheduleTripCard/ScheduleTripCard";
import { SearchResultListCard } from "./SearchResultListCard/SearchResultListCard";
import { SearchTripCard } from "./SearchTripCard/SearchTripCard";
import { TripCardTypeProps } from "./TripCard";
import SimpleRouteCard from "./SimpleRouteCard/SimpleRouteCard";

type AnyTripCardProps =
  | (TripCardTypeProps & {
      index: number;
      isDragging?: boolean;
      isPendingDragChanges?: boolean;
    })
  | RouteTripCardProps
  | ScheduleOrSegmentTripCardProps;

export function AnyTripCard(cardProps: AnyTripCardProps) {
  const isInlineSearchResults = useIsTripsAsCoreFullExperience();
  const layout = useLayout();
  const isMobileTripsV2 = useFeature("TripsV2OnMobile");
  const { isMultiTrip } = useTripPlannerContext();
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);

  if (isMultiTrip || (isMobileTripsV2 && layout === "mobile")) {
    return (
      <SimpleRouteCard
        {...cardProps}
        onboarding={!screenKey && cardProps.index === 0 && layout !== "mobile"}
      />
    );
  } else if (isInlineSearchResults) {
    return (
      <SearchResultListCard
        {...cardProps}
        searchResponse={cardProps.searchResponse as SearchResponse}
      />
    );
  }

  // TODO: Remove this switch statement when mobile trip planner begins using SearchResultListCard instead.
  switch (cardProps.type) {
    case "search":
    case "searchPrompt": {
      if (!cardProps.searchResponse) {
        return <SearchCard {...cardProps} />;
      } else {
        return <SearchTripCard {...cardProps} />;
      }
    }

    case "route": {
      return <RouteTripCard {...cardProps} />;
    }

    case "segment":
    case "schedule": {
      return <ScheduleOrSegmentTripCard {...cardProps} />;
    }
    default:
      console.error(`Incorrect card type ${cardProps.type} in local storage`);
      return null;
  }
}
