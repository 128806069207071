import { useState } from "react";
import { useIntl } from "react-intl";
import { BlurAwayListener } from "src/components/BlurAwayListener/BlurAwayListener";
import { border_radius } from "src/design-system/tokens/border";
import { CalendarIcon } from "src/svg/CalenderIcon";
import styled from "styled-components";
import { ButtonBase } from "../../../components/Button/ButtonBase";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";

import messages from "../../SegmentScreen/SegmentHeader/DatePicker/DynamicDatePicker/DynamicDatePicker.message";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { TripDatePicker } from "./TripDatePicker";
import { tripDateMessages } from "./TripDatePicker.messages";
import { getDateFormatText } from "./utils";

export function TripDatePickerButton() {
  const intl = useIntl();
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <StyledBlurAway onBlurAway={() => setIsPickerOpen(false)} blurAfterClick>
      <StyledDesktopHeaderPill
        onClick={() => {
          setIsPickerOpen(!isPickerOpen);
        }}
        aria-label={intl.formatMessage(tripDateMessages.updateAria)}
        role="button"
        isOpen={isPickerOpen}
      >
        <CalendarIcon tint="primaryOnLight" size="md" />
        <DatePickerText />
      </StyledDesktopHeaderPill>
      {isPickerOpen ? (
        <TripDatePicker
          onSearch={() => {
            setIsPickerOpen(false);
          }}
          closePicker={() => {
            setIsPickerOpen(false);
          }}
        />
      ) : null}
    </StyledBlurAway>
  );
}

function DatePickerText() {
  const intl = useIntl();
  const { tripPlannerDetails } = useTripPlannerContext();

  const { startDate: startDateISOString } = tripPlannerDetails;

  const nightsCount = 8; // TODO: Rob / Jingle Link to lifted state when available

  if (!startDateISOString) {
    return <AddDateLabel>{intl.formatMessage(messages.addDate)}</AddDateLabel>;
  }

  const startDate = new Date(startDateISOString);

  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + nightsCount);

  const formattedDateText = getDateFormatText(intl, startDate, endDate);

  return <AddDateLabel>{formattedDateText}</AddDateLabel>;
}

const StyledDesktopHeaderPill = styled(ButtonBase)<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-evenly;

  min-width: 113px;
  gap: ${spacing.md};
  margin-top: ${spacing.xl};
  padding: ${spacing.lg} ${spacing.xl};

  background-color: ${color.white};
  border-radius: ${border_radius.rounded_lg};
  border: ${(props) =>
    props.isOpen ? `1px solid ${color.black}` : `1px solid ${color.n30}`};

  &:hover {
    background-color: ${color.n30};
  }

  &:active {
    background-color: ${color.n30};
    border-color: ${color.n50};
  }

  span {
    align-items: center;
  }
`;

const AddDateLabel = styled.span`
  color: ${color.black};

  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.tight};
`;

const StyledBlurAway = styled(BlurAwayListener)`
  width: fit-content;
`;
