import { useNavigate } from "react-router";
import { border_radius } from "src/design-system/tokens/border";
import styled, { css } from "styled-components";
import { Navbar } from "../../components/Navbar/Navbar";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import {
  desktopLayout,
  largeDesktopLayout,
  useLayout,
} from "../../utils/hooks/useLayout";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import {
  CurrencyScreenContent,
  useCurrencyPreferenceScreen,
} from "../CurrencyScreen/CurrencyScreen";
import {
  DistanceScreenContent,
  useDistancePreferenceScreen,
} from "../DistanceScreen/DistanceScreen";
import {
  LanguageScreenContent,
  useLanguagePreferenceScreen,
} from "../LanguageScreen/LanguageScreen";
import {
  TimeFormatScreenContent,
  useTimeFormatPreferenceScreen,
} from "../TimeFormatScreen/TimeFormatScreen";

type Preference = "distance" | "language" | "currency" | "timeFormat";
type Props = {
  preference: Preference;
};

const screenContents: Record<Preference, () => JSX.Element> = {
  distance: DistanceScreenContent,
  language: LanguageScreenContent,
  currency: CurrencyScreenContent,
  timeFormat: TimeFormatScreenContent,
};

export function PreferenceScreen(props: Props) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();

  const InnerContent = screenContents[props.preference];

  const { heading: distanceHeading, description: distanceDescription } =
    useDistancePreferenceScreen();
  const { heading: languageHeading, description: languageDescription } =
    useLanguagePreferenceScreen();
  const { heading: currencyHeading, description: currencyDescription } =
    useCurrencyPreferenceScreen();
  const { heading: timeFormatHeading, description: timeFormatDescription } =
    useTimeFormatPreferenceScreen();

  const screenTexts: Record<
    Preference,
    { heading: string; description?: string }
  > = {
    distance: {
      heading: distanceHeading,
      description: distanceDescription,
    },
    language: {
      heading: languageHeading,
      description: languageDescription,
    },
    currency: {
      heading: currencyHeading,
      description: currencyDescription,
    },
    timeFormat: {
      heading: timeFormatHeading,
      description: timeFormatDescription,
    },
  };

  const { heading, description } = screenTexts[props.preference];

  function handleBackClick() {
    // go back to main user preferences screen
    navigateToNewState(navigate, { preferencesScreen: "main" }, location);
  }

  return (
    <>
      {layout === "mobile" || layout === "tablet" ? (
        <Navbar headingText={heading} onBackClick={handleBackClick} />
      ) : (
        description && <HeadingText>{description}</HeadingText>
      )}
      <Content>
        <InnerContent />
      </Content>
    </>
  );
}

const Content = styled.div`
  padding: ${spacing.xxl} ${spacing.xl};
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));
  ${desktopLayout} {
    padding-top: calc(${spacing.xxl} + ${spacing.xxxl});
  }
  ${largeDesktopLayout} {
    padding: 0;
    margin: ${spacing.xxxl} 0 ${spacing.xxl} 0;
    min-height: unset;
    max-height: 269px;
    overflow-y: auto;

    // Change the scrollbar to be less intrusive and different color
    // and move it outside the content area
    width: calc(100% + ${spacing.md});
    padding-right: ${spacing.md};
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 4px; /* for Firefox */
    scrollbar-color: ${color.grey1} ${color.white};
    &::-webkit-scrollbar {
      width: 4px;
      margin: 0 -4px 0 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${color.grey1};
      border-radius: 2px;
      border-color: ${color.grey1};
    }
    &::-webkit-scrollbar-track {
      background-color: ${color.white};
      border-radius: 2px;
      border-color: ${color.white};
      outline: none;
    }
  }
`;

const HeadingText = styled.h2`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.md};
  margin-left: ${spacing.xl};
  ${largeDesktopLayout} {
    margin-top: ${spacing.xxxl};
    margin-bottom: ${spacing.xxxl};
    margin-left: 0;
  }
`;

export const preferenceStyle = css`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  padding: ${spacing.lg};
  min-height: 48px;
  border: 1px solid ${color.n30};
  // Border - we do want have double borders between the items
  border-color: ${color.n30};
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  &:first-of-type {
    border-width: 1px 1px 1px 1px;
    border-radius: ${border_radius.rounded_md} ${border_radius.rounded_md} 0px
      0px;
  }
  &:last-of-type {
    border-radius: 0px 0px ${border_radius.rounded_md}
      ${border_radius.rounded_md};
  }
`;
