import { APIProvider as VisGlApiProvider } from "@vis.gl/react-google-maps";
import { lazy, Suspense } from "react";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import type { SearchResponse } from "./api/SearchResponse";
import {
  MapErrorFallback,
  MapLoadError,
} from "./components/DefaultErrorBoundary/DefaultErrorBoundary";
import { GOOGLE_MAPS_KEY } from "./components/Map/constants";
import { MapLoadingPlaceholder } from "./components/Map/MapLoadingPlaceholder";
import { LazyTripPlannerMap } from "./components/Map/TripPlannerMap/LazyTripPlannerMap";
import type { ReturnStage } from "./utils/hooks/useTypedLocation";
import useUser from "./utils/hooks/useUser";
import logBreadcrumbs from "./utils/logBreadcrumbs";
import logError from "./utils/logError";
import { isHandledImportError } from "./utils/vitePreloadErrorHandler";

type Props = {
  isTripScreen: boolean;
  shouldRenderMap: boolean;
  searchResponse?: SearchResponse;
  returnsFlowLocation?: ReturnStage;
};

export default function MapPane({
  isTripScreen,
  shouldRenderMap,
  searchResponse,
  returnsFlowLocation,
}: Props) {
  const Map = isTripScreen ? LazyTripPlannerMap : LazySearchMap;

  return (
    <ErrorBoundary
      FallbackComponent={MapErrorFallback}
      onError={(error) => {
        if (isHandledImportError(error)) {
          return;
        }
        // Map load errors are often cause by user network problems, so
        // are not critical
        if (error instanceof MapLoadError) {
          console.warn(error);
          return;
        }
        logError(error);
      }}
    >
      <MapsApiProvider>
        <MapLoadingPlaceholder />
        <Suspense fallback={null}>
          {shouldRenderMap && searchResponse && (
            <Map
              searchResponse={searchResponse}
              returnsFlowLocation={returnsFlowLocation}
            />
          )}
        </Suspense>
      </MapsApiProvider>
    </ErrorBoundary>
  );
}

let mapLanguage: string | undefined;

export function MapsApiProvider({ children }: { children: React.ReactNode }) {
  const languageCode = useUser().language;
  const { showBoundary } = useErrorBoundary();

  // VisGl complains massively if we ever change the language after the map api has first loaded.
  // Using a global variable means even with multiple MapsApiProvider components the same language is used.
  // See - https://visgl.github.io/react-google-maps/docs/api-reference/components/api-provider
  mapLanguage ??= languageCode;

  return (
    <VisGlApiProvider
      apiKey={GOOGLE_MAPS_KEY}
      language={mapLanguage}
      libraries={["geometry"]}
      onError={(error) =>
        showBoundary(new MapLoadError("Failed to load map api", error))
      }
    >
      {children}
    </VisGlApiProvider>
  );
}

const LazySearchMap = lazy(async () => {
  logBreadcrumbs("Map", "Requesting Map chunk");
  return await import(
    /* webpackChunkName: "Map" */
    "./components/Map/Map"
  );
});
