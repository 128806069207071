import color from "src/design-system/tokens/color";

import React from "react";
import { ButtonBase } from "src/components/Button/ButtonBase";
import styled from "styled-components";

import { useIntl } from "react-intl";
import { Plus } from "src/svg/tripplanner/Plus";
import { font_weight } from "src/design-system/tokens/typography";
import { spacing } from "src/design-system/tokens/spacing";
import messages from "./AddDestinationTimelineButton.messages";

interface AddDestinationTimelineButtonProps {
  onClick: () => void;
  isDisabled: boolean;
  isTimeline?: boolean;
}

export function AddDestinationTimelineButton(
  props: AddDestinationTimelineButtonProps
) {
  const { onClick, isDisabled, isTimeline } = props;
  const intl = useIntl();

  return (
    <AddButton isDisabled={isDisabled} disabled={isDisabled} onClick={onClick}>
      <AddSvg tint={isDisabled ? "grey3" : "pink"} isTimeline={isTimeline} />
      {intl.formatMessage(messages.addDestination)}
    </AddButton>
  );
}

const AddButton = styled(ButtonBase)<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  color: ${(props) =>
    props.isDisabled ? color.text.tertiary : color.bg.brand.fill};
  font-weight: ${font_weight.medium};
  padding: ${spacing.xl};
  padding-left: 0;
`;

const AddSvg = styled(Plus)<{ isTimeline?: boolean }>`
  margin-left: 14px;
  margin-right: ${(props) => (props.isTimeline ? spacing.xl : "16px")};
  width: 14px;
  height: 14px;
  z-index: 1;
`;
