import {
  ChangeEvent,
  KeyboardEvent,
  PropsWithChildren,
  ReactNode,
  useRef,
} from "react";
import { border_radius } from "src/design-system/tokens/border";
import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import { largeDesktopLayout } from "../../utils/hooks/useLayout";

type RadioProps = {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function Radio(props: PropsWithChildren<RadioProps>) {
  const radioButton = useRef<HTMLInputElement>(null);
  const { id, name, value, icon, subtitle, checked, onChange } = props;

  function handleKeyPress(event: KeyboardEvent<HTMLLabelElement>) {
    if (
      (event.code === "Space" || event.code === "Enter") &&
      radioButton.current !== null
    ) {
      radioButton.current.click();
    }
  }

  return (
    <Row
      htmlFor={id}
      checked={checked}
      tabIndex={0}
      onKeyPress={handleKeyPress}
    >
      <Input
        ref={radioButton}
        tabIndex={-1}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        aria-checked={checked}
      />
      <Title checked={checked}>{props.children}</Title>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {subtitle && <Subtitle checked={checked}>{subtitle}</Subtitle>}
    </Row>
  );
}

const Title = styled.span<{ checked: boolean }>`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  color: ${color.n300};
  font-weight: ${({ checked }) =>
    checked ? fontWeight.medium : fontWeight.normal};

  ${largeDesktopLayout} {
    order: 1;
    height: 18px;
    line-height: unset;
  }
`;

const Subtitle = styled(Title)<{ checked: boolean }>`
  font-weight: ${({ checked }) =>
    checked ? fontWeight.normal : fontWeight.light};
  ${largeDesktopLayout} {
    order: 2;
    width: 100%;
    font-size: ${fontSize.h6};
    height: 16px;
    line-height: unset;
  }
`;

const Row = styled.label<{ checked: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  background-color: ${({ checked }) => (checked ? color.n30 : color.white)};
  padding: ${spacing.xl};
  cursor: pointer;
  // Border - we do want have double borders between the items
  border-color: ${color.n30};
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  &:first-of-type {
    border-width: 1px 1px 1px 1px;
    border-radius: ${border_radius.rounded_md} ${border_radius.rounded_md} 0px
      0px;
  }
  &:last-of-type {
    border-radius: 0px 0px ${border_radius.rounded_md}
      ${border_radius.rounded_md};
  }

  &:hover {
    background-color: ${color.n40};
  }

  ${largeDesktopLayout} {
    border-radius: ${border_radius.rounded_md};
    min-height: 66px;
    border-width: 1px;
    column-gap: ${spacing.lg};
    justify-content: flex-start;
    flex-wrap: wrap;
    &:first-of-type {
      border-radius: ${border_radius.rounded_md};
    }
    &:last-of-type {
      border-radius: ${border_radius.rounded_md};
    }
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
  ${largeDesktopLayout} {
    order: 0;
  }
`;

const Input = styled.input`
  cursor: inherit;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
