import { defineMessages } from "react-intl";

const messages = defineMessages({
  addDestination: {
    id: "placeInputList.addDestination",
    description: "Label for button to add another destination to the list",
    defaultMessage: "Add Destination",
  },
});

export default messages;
