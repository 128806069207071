import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { TripPlannerMobileMap } from "src/components/Map/TripPlannerMap/TripPlannerMobileMap";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useTripTransportIndex } from "src/utils/hooks/useTripTransportIndex";
import styled, { css } from "styled-components";
import { useFeature } from "src/feature/useFeature";
import { primitive } from "src/design-system/tokens/color";
import { Button } from "src/design-system/components/Button/Button";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { Icon } from "src/design-system/components/Icon/Icon";
import { ChevronLeft } from "src/svg/designSystem/ChevronLeft";
import useUser from "src/utils/hooks/useUser";
import { useState } from "react";
import { Bookmark } from "src/svg/tripplanner/Bookmark";
import useSearch from "src/utils/hooks/useSearch";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useAnalyticsCoreExperience } from "../../../analytics/useAnalyticsCoreExperience/useAnalyticsCoreExperience";
import { color, spacing } from "../../../theme";
import { desktopLayout, useLayout } from "../../../utils/hooks/useLayout";
import {
  ReturnStage,
  useTypedLocation,
} from "../../../utils/hooks/useTypedLocation";
import messages from "../TripPlannerScreen.messages";
import { TripPlannerScreenLoaded } from "../TripPlannerScreenLoaded";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { MemoizedTripPlannerDrawer } from "../Drawer/TripPlannerDrawer";
import LargeHeading from "../Heading/LargeHeading";
import { StackedNavigation } from "../StackedNavigation/StackedNavigation";
import { useTripPlannerComponents } from "../hooks/useTripPlannerComponents";
import { TripPlannerSkeleton } from "../TripPlannerSkeleton";
import LazyReorderTrip from "../ReorderTrip/ReorderTripScreen/LazyReorderTrip";
import SmallHeading from "../Heading/SmallHeading";
import { UserAccountTakeover } from "../UserAccountTakeover/UserAccountTakeover";
import { SaveWrapper } from "../SingleTrip/SingleTripScreen";

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function MultiTripScreen({ returnsFlowLocation }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const transportIndex = useTripTransportIndex();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const { user } = useUser();
  const isUserLoggedIn = !!user?.id;
  const { tripPlannerDetails, apiState } = useTripPlannerContext();
  const components = useTripPlannerComponents("complete");
  const isTripsV2OnMobile =
    useFeature("TripsV2OnMobile") && layout === "mobile";
  const [showSigninTakeover, setShowSigninTakeover] = useState(false);
  const isTripBreakpointsFeature = useFeature("TripBreakpoints");

  const { searchResponse } = useSearch();
  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "TripPlanner",
    undefined,
    components
  );

  useAnalyticsCoreExperience({
    experienceHash: `${location.pathname}:r${location.hash}/`,
    analyticsLabel: "TripPlanner Screen",
  });

  function handleShowSavedTrips() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  function getColor() {
    if (layout === "desktop") {
      return primitive.neutral_400;
    } else if (isTripsAsCoreHoldback && !isTripsV2OnMobile) {
      return color.white;
    } else {
      return color.grey1;
    }
  }

  const TripPlannerContent = (
    <>
      {layout !== "mobile" && !isTripsV2OnMobile && <LargeHeading />}
      <Content
        $isReordering={components.includes("ReorderPage")}
        isTripsAsCoreHoldback={isTripsAsCoreHoldback}
        $color={getColor()}
      >
        {isTripsV2OnMobile && isUserLoggedIn && (
          <ButtonWrapper>
            <Button
              onPress={handleShowSavedTrips}
              size="small"
              variant="subtle"
            >
              <Icon size="small">
                <ChevronLeft tint="cod" />
              </Icon>{" "}
              {intl.formatMessage(messages.savedTrips)}
            </Button>
          </ButtonWrapper>
        )}

        {isTripsV2OnMobile && !isUserLoggedIn && (
          <SaveWrapper>
            <Button
              onPress={() => setShowSigninTakeover(true)}
              size="medium"
              variant="secondary"
            >
              <Icon size="small">
                <Bookmark tint="cod" />
              </Icon>{" "}
              {intl.formatMessage(messages.save)}
            </Button>
          </SaveWrapper>
        )}

        <UserAccountTakeover
          isDropdownOpen={showSigninTakeover}
          handleClose={() => setShowSigninTakeover(false)}
          handleClickOffDropdown={() => setShowSigninTakeover(false)}
        />

        <>
          {!isTripsV2OnMobile &&
            layout === "mobile" &&
            components.includes("TripPlannerTitle") && (
              <SmallHeading titleText={intl.formatMessage(messages.myTrip)} />
            )}
          {components.includes("TripPlannerMobileMap") && (
            <TripPlannerMobileMap />
          )}

          {isTripsV2OnMobile && components.includes("TripPlannerTitle") && (
            <LargeHeading />
          )}

          {components.includes("TripPlannerCards") &&
            apiState.fetchState !== "fetching" &&
            !components.includes("ReorderPage") && <TripPlannerScreenLoaded />}

          {components.includes("ReorderPage") && (
            <LazyReorderTrip places={tripPlannerDetails.places} />
          )}

          {components.includes("TripPlannerSkeleton") && (
            <TripPlannerSkeleton />
          )}

          {isTripsAsCoreHoldback && layout !== "mobile" && <Divider />}
        </>
      </Content>
    </>
  );

  if (
    layout === "desktop" ||
    (isTripBreakpointsFeature && layout !== "mobile")
  ) {
    return (
      <StackedNavigation
        {...{ transportIndex, returnsFlowLocation, TripPlannerContent }}
      />
    );
  } else {
    return (
      <>
        {TripPlannerContent}
        {transportIndex !== undefined && (
          <>
            <MemoizedTripPlannerDrawer
              returnsFlowLocation={returnsFlowLocation}
              transportIndex={transportIndex}
            />
          </>
        )}
      </>
    );
  }
}

const Content = styled.div<{
  $isReordering?: boolean;
  isTripsAsCoreHoldback: boolean;
  $color?: string;
}>`
  width: 100%;
  background-color: ${(props) => props.$color};
  position: relative;
  padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));

  ${desktopLayout} {
    max-width: 100%;
    min-height: unset;
  }

  ${({ $isReordering }) =>
    $isReordering &&
    css`
      padding-bottom: 64px;

      ${desktopLayout} {
        min-height: calc(100vh - 56px);
      }
    `}
`;

const Divider = styled.hr`
  border: none;
  height: ${spacing.xl};

  // These props ensure the divider sits ontop of the pane left/right padding.
  position: relative;
  width: calc(100% + ${spacing.xl} * 2);
  left: -${spacing.xl};

  background: ${color.n10};
  margin-top: ${spacing.xxxxl};
  margin-bottom: ${spacing.xxxl};
  border-top: 1px solid ${color.n30};
  border-bottom: 1px solid ${color.n30};
`;

const ButtonWrapper = styled.div`
  margin-left: -${spacing.lg};
  margin-bottom: ${spacing.lg};
`;
