import Cookies from "js-cookie";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SignInButton } from "src/auth/components/SignInButton/SignInButton";
import { Button } from "src/components/Button/Button";
import { Plus } from "src/svg/tripplanner/Plus";
import { color, spacing } from "src/theme";
import useUser from "src/utils/hooks/useUser";
import styled from "styled-components";
import { UserAccountTakeover } from "../UserAccountTakeover/UserAccountTakeover";
import { Banner } from "./Banner";
import messages from "./MyTrips.messsages";
import { TripList } from "./TripList";

const HAS_SEEN_MY_TRIPS_LOGIN_BANNER = "hasSeenMyTripsLoginBanner";

type Props = {
  onClose: () => void;
  onNewTrip: () => void;
  numVisibleTrips: number;
  setNumVisibleTrips: (num: number) => void;
};

export function MyTrips(props: Props) {
  const intl = useIntl();
  const { user } = useUser();
  const isUserLoggedIn = !!user?.id;
  const [showSigninTakeover, setShowSigninTakeover] = useState(false);
  const seenLoginBanner =
    Cookies.get(HAS_SEEN_MY_TRIPS_LOGIN_BANNER) === "true";

  function closeMyTripsModal() {
    props.onClose();

    if (isUserLoggedIn && !seenLoginBanner) {
      Cookies.set(HAS_SEEN_MY_TRIPS_LOGIN_BANNER, "true", {
        expires: 365, // Set cookie to expire in a year.
      });
    }
  }

  return (
    <>
      {isUserLoggedIn ? (
        <>
          {!seenLoginBanner && (
            <DropdownHeader>
              <Banner
                status="success"
                heading={intl.formatMessage(messages.successHeading)}
                text={intl.formatMessage(messages.successMessage)}
              />
            </DropdownHeader>
          )}
          <TripList
            onSetTrip={closeMyTripsModal}
            numVisibleTrips={props.numVisibleTrips}
            setNumVisibleTrips={props.setNumVisibleTrips}
          />
          <DropdownFooter>
            <NewTripButton
              backgroundColor="transparent"
              textColor="primaryOnLight"
              size="large"
              onClick={props.onNewTrip}
              leftIcon={<Plus tint="cod" />}
              leftIconSize="sm"
              border={color.n30}
              borderRadius="rounded_md"
            >
              {intl.formatMessage(messages.newTrip)}
            </NewTripButton>
          </DropdownFooter>
        </>
      ) : (
        <>
          <DropdownHeader>
            <Banner
              status="info"
              heading={intl.formatMessage(messages.infoHeading)}
              text={intl.formatMessage(messages.infoMessage)}
            />
          </DropdownHeader>

          <TripList
            onSetTrip={closeMyTripsModal}
            numVisibleTrips={props.numVisibleTrips}
            setNumVisibleTrips={props.setNumVisibleTrips}
            isLoggedOut
          />
          <DropdownFooter>
            <SignInButton
              handleClickButton={() => setShowSigninTakeover(true)}
              text={intl.formatMessage(messages.signIn)}
              variant="tripsContextLg"
              fullWidth
            />
          </DropdownFooter>
        </>
      )}

      <UserAccountTakeover
        isDropdownOpen={showSigninTakeover}
        handleClose={() => setShowSigninTakeover(false)}
        handleClickOffDropdown={() => setShowSigninTakeover(false)}
      />
    </>
  );
}

const DropdownFooter = styled.div`
  padding: ${spacing.md} ${spacing.xl};
`;

const DropdownHeader = styled.div`
  padding: ${spacing.md} ${spacing.xl} 0;
`;

const NewTripButton = styled(Button)`
  // TODO: These hover colours are from the new design system.
  // Update button component values with these when we swap to the new design system.
  &:hover {
    background-color: ${color.n30};
    border-color: ${color.n40};
  }
`;
