import { darken, lighten } from "polished";
import { ElementType, PropsWithChildren, ReactNode, forwardRef } from "react";
import { border_radius } from "src/design-system/tokens/border";
import styled, { CSSProperties } from "styled-components";
import {
  color,
  fontSize,
  fontWeight,
  iconSize,
  neutralColor,
  spacing,
  textColor,
} from "../../theme";
import { MergeElementProps } from "../../utils/MergeElementProps";
import { Icon } from "../Icon/Icon";
import { ButtonBase } from "./ButtonBase";

type CommonProps = PropsWithChildren<{
  textColor: keyof typeof textColor;
  backgroundColor: keyof typeof color;
  outline?: boolean;
  size?: ButtonBaseProps["size"];
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  inline?: boolean;
  justify?: "flex-start" | "center" | "flex-end" | "space-between";
  className?: string;
  disabledBackgroundColor?: keyof typeof color;
  disabledTextColor?: keyof typeof textColor;
  isLoading?: boolean;
  border?: string;
  borderRadius?: keyof typeof border_radius;
  leftIconSize?: keyof typeof iconSize;
  leftIconSpacing?: keyof typeof spacing;
  rightIconSize?: keyof typeof iconSize;
  rightIconSpacing?: keyof typeof spacing;
  fontSize?: keyof typeof fontSize;
  fontWeight?: keyof typeof fontWeight;
  padding?: CSSProperties["padding"];
  inlineText?: boolean;
}>;

type ButtonElementProps = MergeElementProps<
  "button",
  { href?: undefined; disabled?: boolean }
>;
type AnchorElementProps = MergeElementProps<
  "a",
  { href: string; disabled?: undefined }
>;
type ElementProps = ButtonElementProps | AnchorElementProps;
export type ButtonProps = CommonProps & ElementProps;

type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

function ButtonInner(
  {
    size,
    textColor,
    backgroundColor,
    outline,
    className,
    leftIcon,
    rightIcon,
    inline,
    children,
    disabled,
    disabledBackgroundColor,
    disabledTextColor,
    isLoading,
    justify,
    borderRadius,
    leftIconSize,
    rightIconSize,
    leftIconSpacing,
    rightIconSpacing,
    fontSize,
    fontWeight,
    inlineText,
    ...other
  }: ButtonProps,
  ref: PolymorphicRef<ElementType>
) {
  const sizeOrDefault = size ?? "medium";
  const tag = isAnchorElement(other) ? "a" : "button";

  let elementProps: ElementProps;
  if (isAnchorElement(other)) {
    elementProps = other;
  } else {
    other.type = other.type ?? "button";
    elementProps = other;
  }

  return (
    <StyledButtonBase
      as={tag}
      backgroundColor={backgroundColor}
      outline={outline}
      size={sizeOrDefault}
      inline={inline}
      justify={justify}
      borderRadius={borderRadius}
      className={className}
      disabled={disabled ?? isLoading}
      disabledBackgroundColor={disabledBackgroundColor}
      aria-disabled={disabled ?? isLoading}
      tabIndex={disabled ? -1 : 0}
      ref={ref}
      {...elementProps}
    >
      <Label
        fontSize={fontSize}
        fontWeight={fontWeight}
        textColor={textColor}
        size={sizeOrDefault}
        isDisabled={disabled}
        disabledTextColor={disabledTextColor}
        $isLoading={isLoading}
        inline={inlineText}
      >
        {leftIcon && (
          <LeftIcon
            size={leftIconSize ?? (sizeOrDefault === "large" ? "lg" : "md")}
            leftIconSpacing={leftIconSpacing}
          >
            {leftIcon}
          </LeftIcon>
        )}
        {children}
        {rightIcon && (
          <RightIcon
            size={rightIconSize ?? (sizeOrDefault === "large" ? "lg" : "md")}
            rightIconSpacing={rightIconSpacing}
          >
            {rightIcon}
          </RightIcon>
        )}
      </Label>
    </StyledButtonBase>
  );
}

export const Button = forwardRef(ButtonInner);

export type ButtonBaseProps = {
  backgroundColor: keyof typeof color;
  outline?: boolean;
  size: "extra-small" | "smaller" | "small" | "medium" | "large";
  inline?: boolean;
  disabledBackgroundColor?: keyof typeof color;
  borderRadius?: keyof typeof border_radius;
  border?: string;
  justify?: "flex-start" | "center" | "flex-end" | "space-between";
  padding?: CSSProperties["padding"];
};

const StyledButtonBase = styled(ButtonBase)<ButtonBaseProps>`
  position: relative;
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${(props) =>
    `border-radius: ${
      props.borderRadius ? border_radius[props.borderRadius] : "4px"
    }`};

  /* Button colour */
  ${(props) => {
    const currentColour = props.disabled
      ? color[props.disabledBackgroundColor ?? "n20"]
      : color[props.backgroundColor];

    if (props.outline) {
      return `border: 1px solid ${currentColour};`;
    } else {
      return `background-color: ${currentColour};`;
    }
  }}

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  width: ${(props: ButtonBaseProps) => (props.inline ? "auto" : "100%")};

  ${(props: ButtonBaseProps) => {
    if (props.size && props.size === "extra-small") {
      return `
        min-height: 24px;
        padding-left: ${spacing.md};
        padding-right: ${spacing.md};`;
    } else if (props.size && props.size === "smaller") {
      return `
        min-height: 32px;
        padding-left: ${spacing.lg};
        padding-right: ${spacing.lg};
      `;
    } else if (props.size && props.size === "small") {
      return `
        min-height: 32px;
        padding-left: ${spacing.xl};
        padding-right: ${spacing.xl};
      `;
    } else if (props.size && props.size === "large") {
      return `
        min-height: 48px;
        padding-left: ${spacing.xxl};
        padding-right: ${spacing.xxl};
      `;
    } else {
      return `
        min-height: 40px;
        padding-left: ${spacing.xxl};
        padding-right: ${spacing.xxl};
      `;
    }
  }}

  ${({ border }) => border && `border: 1px solid ${border}`};
  padding: ${(props: ButtonBaseProps) =>
    props.padding ? props.padding : null};

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.disabledBackgroundColor
          ? color[props.disabledBackgroundColor]
          : color.n20
        : lighten(0.1, color[props.backgroundColor])};

    ${({ border }) => border && `border-color: ${darken(0.2, border)}}`};

    // Reset the background-color and border on touch devices so that they don't get a
    // lingering hover effect after a click event.
    @media (hover: none) {
      background-color: ${(props) =>
        props.disabled
          ? props.disabledBackgroundColor
            ? color[props.disabledBackgroundColor]
            : color.n20
          : color[props.backgroundColor]};

      ${({ border }) => border && `border-color: ${border}`};
    }
  }

  &:active {
    background-color: ${(props) =>
      props.disabled
        ? props.disabledBackgroundColor
          ? color[props.disabledBackgroundColor]
          : color.n20
        : darken(0.1, color[props.backgroundColor])};
  }

  // We're currently missing focus styles because they're non-trivial to
  // implement correctly, see: https://rome2rio.atlassian.net/browse/EXP-785
`;

const RightIcon = styled(Icon)<{
  rightIconSpacing?: keyof typeof spacing;
}>`
  margin-left: ${({ rightIconSpacing }) =>
    rightIconSpacing ? spacing[rightIconSpacing] : "8px"};
`;

const LeftIcon = styled(Icon)<{
  leftIconSpacing?: keyof typeof spacing;
}>`
  margin-right: ${({ leftIconSpacing }) =>
    leftIconSpacing ? spacing[leftIconSpacing] : "8px"};
`;

type LabelProps = {
  textColor: keyof typeof textColor;
  size: ButtonBaseProps["size"];
  disabledTextColor?: keyof typeof textColor;
  isDisabled?: boolean;
  $isLoading?: boolean;
  fontSize?: keyof typeof fontSize;
  fontWeight?: keyof typeof fontWeight;
  inline?: boolean;
};

const Label = styled.span<LabelProps>`
  align-items: inherit;
  color: ${(props) =>
    props.isDisabled
      ? props.disabledTextColor
        ? color[props.disabledTextColor]
        : neutralColor.n40
      : textColor[props.textColor]};
  display: ${({ inline }) => (inline ? "inline" : "inherit")};
  font-size: ${(props) => {
    if (props.size === "extra-small") {
      return fontSize.small;
    }
    if (props.size === "large") {
      return fontSize.body;
    }
    return fontSize.h6;
  }};
  ${(props) => props.fontSize && `font-size: ${fontSize[props.fontSize]}`};
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : fontWeight.medium};
  justify-content: inherit;
  width: 100%; // Ensures correct width on Safari
  // Hide contents when isLoading, but keep it in the layout so the width stays the same.
  ${(props) => props.$isLoading && `visibility: hidden;`}
`;

function isAnchorElement(
  props: ButtonElementProps | AnchorElementProps
): props is AnchorElementProps {
  return !!props.href;
}
