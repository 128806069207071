import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "src/components/Button/Button";
import { Plus } from "src/svg/tripplanner/Plus";
import styled from "styled-components";
import { TripPlannerAutocomplete } from "src/domain/TripPlanner/TripPlannerAutocomplete/TripPlannerAutocomplete";
import { useFeature } from "src/feature/useFeature";
import { DottedLine } from "src/domain/TripPlanner/DottedLine/DottedLine";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { AutocompletePlace } from "../../../api/AutocompleteResponse";
import { spacing } from "../../../theme";
import { AddDestinationTimelineButton } from "../AddDestinationTimelineButton/AddDestinationTimelineButton";
import messages from "./AddDestinationButton.messages";

type AddDestinationButtonProps = {
  onSelectDestination: (place: AutocompletePlace) => void;
};

export function AddDestinationButton(props: AddDestinationButtonProps) {
  const intl = useIntl();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const isTripsV2OnMobile = useFeature("TripsV2OnMobile");

  const staticButtonRef = useRef<HTMLDivElement>(null);

  function toggleDialog() {
    const action = `${!isDialogOpen ? "Open" : "Close"}:AddDestination`;
    sendAnalyticsInteractionEvent("TripPlanner", action);
    setDialogOpen(!isDialogOpen);
  }

  function onSelectDestination(place: AutocompletePlace) {
    toggleDialog();
    if (place) {
      props.onSelectDestination(place);
    }
  }

  return (
    <AddDestinationContainer>
      {isTripsV2OnMobile ? (
        <AddDestinationWrapper>
          <DottedLine />
          <AddDestinationTimelineButton
            data-testid="add-destination-button"
            isDisabled={false}
            onClick={toggleDialog}
            isTimeline
          />
        </AddDestinationWrapper>
      ) : (
        <StyledButton
          data-testid="add-destination-button"
          backgroundColor="cod"
          textColor="primaryOnDark"
          fontWeight="normal"
          fontSize="body"
          onClick={toggleDialog}
          justify="flex-start"
          borderRadius="rounded_xxl"
          leftIcon={<Plus tint="white" />}
          leftIconSize="md"
          leftIconSpacing="lg"
          ref={staticButtonRef}
          inline
        >
          {intl.formatMessage(messages.addDestination)}
        </StyledButton>
      )}

      {isDialogOpen && (
        <TripPlannerAutocomplete
          onSelectOption={onSelectDestination}
          handleClose={toggleDialog}
        />
      )}
    </AddDestinationContainer>
  );
}

const StyledButton = styled(Button)`
  padding: 0 ${spacing.xl} 0 ${spacing.lg};
  margin: 0 ${spacing.sm};
`;

const AddDestinationContainer = styled.div`
  padding-top: ${spacing.lg};
`;

const AddDestinationWrapper = styled.div`
  margin-top: -${spacing.sm};
  margin-left: 3px;
`;
