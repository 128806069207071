import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import logBreadcrumbs from "src/utils/logBreadcrumbs";
import { useRegisterOnConsent } from "src/utils/hooks/useRegisterOnConsent";
import { sendAnalyticsNonInteractionEvent } from "../../analytics/sendAnalyticsNonInteractionEvent";
import { useExperimentConfig } from "../../experiment/useExperimentConfig";
import { useFeature } from "../../feature/useFeature";
import useSearch from "../../utils/hooks/useSearch";
import { subscribeToSlotRenderEndedEvents } from "./admanager";
import { SlotConfigId, networkCode, slotConfigMap } from "./config";
import { getTargeting } from "./targeting";

export const GOOGLE_ADS_CONSENT_PURPOSES_REQUIRED = [1, 8, 9, 10];
export const GOOGLE_ADS_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];

// Helpful docs for the GPT library used in this file:
// https://developers.google.com/doubleclick-gpt/reference

// Make sure that googletag.cmd exists as it may not have loaded yet.
window.googletag = window.googletag || {};
// When the GPT JavaScript is loaded, it looks through the cmd array and executes all the functions in order.
window.googletag.cmd = window.googletag.cmd || [];

// Ensure media.net's queue exists
window.mnjs = window.mnjs || {};
window.mnjs.que = window.mnjs.que || [];

// Reducing the length of an annoyingly long function name
export const logAdAnalytics = sendAnalyticsNonInteractionEvent.bind(
  null,
  "Ads"
);

type Props = {
  slotConfigId: SlotConfigId;
  onFilled?: () => void;
  onNotFilled?: () => void;
  className?: string;
  setAdViewed?: Dispatch<SetStateAction<boolean>>;
  refreshCount?: number;
};

/**
 * TODO: Eventually, DisplayAd can do the job of TieredDisplayAd too.
 * If we add an `onFillFailed` and a `tier` prop to DisplayAd, we can update the tier prop every time the ad fails to fill.
 * This will allow us to reuse all the logic here rather than duplicating it in TieredDisplayAd.
 */

export function DisplayAd({
  slotConfigId,
  onFilled,
  onNotFilled,
  className,
  setAdViewed,
  refreshCount,
}: Props) {
  const { searchResponse } = useSearch();
  const experimentConfig = useExperimentConfig();
  const adFillStrategy = useFeature("FillAds");
  const delayLoadingPrebid = useFeature("DelayPrebidUntilConsentReady");

  const { divId } = slotConfigMap[slotConfigId];

  // We purposefully only take targetting info from the first search response
  // so we don't need refresh the ads if the user performs a new search.
  const savedTargeting = useRef<ReturnType<typeof getTargeting>>();

  if (!savedTargeting.current && searchResponse) {
    savedTargeting.current = getTargeting({
      searchResponse,
      experimentConfig,
      adFillStrategy,
      tier: undefined,
      refreshCount,
    });
  }
  const targeting = savedTargeting.current;

  const hasDisplayAdConsent = useRegisterOnConsent(
    GOOGLE_ADS_CONSENT_PURPOSES_REQUIRED,
    GOOGLE_ADS_LEGITIMATE_INTEREST_PURPOSES_REQUIRED
  );

  useEffect(() => {
    setAdViewed?.(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup events that monitor the ad status
  useEffect(() => {
    const unsubscribe = subscribeToSlotRenderEndedEvents((event) => {
      // Each use of this component attaches a new event listener on the pubads
      // object. We need to filter out any events that were not for this slot.
      if (divId !== event.slot.getSlotElementId()) {
        return;
      }

      // When we fail to get an ad
      if (event.isEmpty) {
        logAdAnalytics("FailedToFill", `${slotConfigId}`);
        onNotFilled?.();
        setAdViewed?.(true);
      } else {
        onFilled?.();
        logBreadcrumbs("Ads", `Ad rendered: ${slotConfigId}`);
        logAdAnalytics("Gpt:Render", `${slotConfigId}`);
      }
    });

    return unsubscribe;
  }, [divId, slotConfigId, onFilled, onNotFilled, setAdViewed]);

  useEffect(() => {
    if (!window.googletag?.cmd) return;

    const onViewableListener = (
      event: googletag.events.ImpressionViewableEvent
    ) => {
      const slot = event.slot;

      if (slot.getSlotElementId() === divId) {
        sendAnalyticsNonInteractionEvent(
          "Ads",
          "gpt:AdViewed",
          `${slotConfigId}`
        );
        window.googletag
          .pubads()
          .removeEventListener("impressionViewable", onViewableListener);
        setAdViewed?.(true);
      }
    };

    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener("impressionViewable", onViewableListener);
    });

    return () => {
      // Clean up
      window.googletag.cmd.push(() =>
        window.googletag
          .pubads()
          .removeEventListener("impressionViewable", onViewableListener)
      );
    };
  }, [divId, setAdViewed, slotConfigId]);

  useEffect(() => {
    if (!window.googletag?.cmd) return;

    const onRequestListener = (event: googletag.events.SlotRequestedEvent) => {
      const slot = event.slot;

      if (slot.getSlotElementId() === divId) {
        logAdAnalytics("Requested", `${slotConfigId}`);
      }
    };

    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener("slotRequested", onRequestListener);
    });

    return () => {
      // Clean up
      window.googletag.cmd.push(() =>
        window.googletag
          .pubads()
          .removeEventListener("slotRequested", onRequestListener)
      );
    };
  }, [divId, slotConfigId]);

  // Setup the ad itself
  useEffect(() => {
    // We can't request an ad if googletag has been removed by an ad blocker.
    if (!window.googletag?.cmd) {
      logAdAnalytics("Error", "initGpt: googletag.cmd missing");
      return;
    }

    // We can't request an ad until we have a search response because
    // we need to know the experiment config to determine experiment targeting
    // for the MobileWebRewrite2020Holdback experiment.
    if (!targeting) {
      return;
    }

    // For the purposes of header bidding, we need to ensure the user has given consent
    // before defining the ad slot.
    if (!hasDisplayAdConsent) {
      return;
    }

    // Queue up loading of the ad
    let slot: googletag.Slot;
    window.googletag?.cmd.push(function () {
      const { divId, adUnit, sizes, sizeMapping } = slotConfigMap[slotConfigId];

      // Switch to a test namespace for adunits when in testing mode.
      const adUnitRoot =
        `/${networkCode}` + (targeting.zTestEnabled ? "/ztest-0" : "");

      slot = window.googletag
        .defineSlot(`${adUnitRoot}/${adUnit}`, sizes, divId)
        .addService(window.googletag.pubads())
        .updateTargetingFromMap(targeting);

      // Responsively size ads based on viewport width
      if (sizeMapping) {
        slot.defineSizeMapping(sizeMapping);
      }

      logAdAnalytics("Requesting", `${slotConfigId}`);

      // Ensure that the Google bid requests are triggered only after the Media.net Prebid script executes.
      if (delayLoadingPrebid) {
        window.mnjs.que.push(() => {
          window.googletag.display(divId);
        });
      } else {
        window.googletag.display(divId);
      }
    });

    return () => {
      // Cleanup the ad when the component unmounts
      if (slot) {
        window.googletag?.destroySlots([slot]);
      }
    };
  }, [slotConfigId, targeting, hasDisplayAdConsent, delayLoadingPrebid]);

  const config = slotConfigMap[slotConfigId];

  return (
    <div id={config.divId} className={className} data-testid="displayAd" />
  );
}
