import { breakpoints } from "src/theme";

export const networkCode = "14589826";

export const SlotConfigIds = [
  "desktopColumn",
  "desktopColumn2",
  "mobileHeader",
  "rightRail",
  "rightRail2",
  "routeScreen",
  "routeScreen2",
  "segmentScreen",
  "segmentScreenBanner",
  "segmentScreenTower",
  "segmentScreenBaseline",
  "singleMobile",
  "singleMobile2",
  "singleDesktop",
  "singleDesktop2",
  "desktopInlineSerpAd",
  "inlineSegmentAd",
] as const;

export type SlotConfig = {
  adUnit: string;
  divId: string;
  sizes: googletag.GeneralSize;
  sizeMapping?: googletag.SizeMappingArray;
  tiers: string[];
  adUnitSubVersion: 0 | 1;
};

export type SlotConfigId = (typeof SlotConfigIds)[number];

export type SlotConfigMap = {
  [slotId in SlotConfigId]: SlotConfig;
};

export const slotConfigMap: SlotConfigMap = {
  mobileHeader: {
    adUnit: "mobile-header-1",
    divId: "mobile-header-1",
    sizes: [
      [300, 50],
      [320, 50],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopColumn: {
    adUnit: "desktop-column-atf-0",
    divId: "desktop-column1-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopColumn2: {
    adUnit: "desktop-column-btf-0",
    divId: "desktop-column2-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  rightRail: {
    adUnit: "Desktop-Rightrail-0",
    divId: "rightrail-ad1",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    // sizeMapping is used to serve ads of different sizes based on the viewport
    // e.g when viewport width ≥ 1740px serve 300x600 sized ads, when ≥ 1260px, 160x600
    sizeMapping: [
      [[breakpoints.xxl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  rightRail2: {
    adUnit: "desktop-rightrail-2nd-0",
    divId: "rightrail-ad2",
    sizes: [
      [160, 600],
      [300, 600],
    ],
    sizeMapping: [
      [[breakpoints.xxl, 0], [[300, 600]]],
      [[breakpoints.lg, 0], [[160, 600]]],
      [[0, 0], []],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleMobile: {
    adUnit: "Mobile-Single-0",
    divId: "mobile-ad-box",
    sizes: [
      [336, 280],
      [300, 250],
      [320, 50],
      [300, 600],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleMobile2: {
    adUnit: "mobile-single-2nd-0",
    divId: "mobile-ad-box2",
    sizes: [
      [336, 280],
      [300, 250],
      [350, 50],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  singleDesktop: {
    adUnit: "Desktop-SingleLeft-0",
    divId: "desktop-pane-ad",
    sizes: [
      [300, 600],
      [336, 280],
      [300, 250],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  singleDesktop2: {
    adUnit: "desktop-singleleft-2nd-0",
    divId: "desktop-pane-ad2",
    sizes: [
      [336, 280],
      [300, 250],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  routeScreen: {
    adUnit: "desktop-tp-routepane-0",
    divId: "desktop-routepane-ad",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    tiers: ["High", "Mid", "Low", "Basement"],
    adUnitSubVersion: 0,
  },
  routeScreen2: {
    adUnit: "desktop-tp-routepane-2nd-0",
    divId: "desktop-routepane-ad2",
    sizes: [[300, 250]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreen: {
    adUnit: "desktop-tp-segmentpane-0",
    divId: "desktop-segmentpane-ad",
    sizes: [[728, 90]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenBaseline: {
    adUnit: "desktop-tp-segmentpane-baseline-0",
    divId: "desktop-segmentpane-ad",
    sizes: [[300, 250]],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenBanner: {
    adUnit: "desktop-tp-segmentpane-2nd-0",
    divId: "desktop-segmentpane-banner-ad",
    sizes: [
      [300, 250],
      [300, 600],
      [728, 90],
    ],
    sizeMapping: [
      [[1260, 0], [[728, 90]]],
      [
        [breakpoints.md, 0],
        [
          [300, 250],
          [300, 600],
        ],
      ],
      [[0, 0], []],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  segmentScreenTower: {
    adUnit: "desktop-tp-map-0",
    divId: "desktop-segmentpane-tower-ad",
    sizes: [
      [300, 250],
      [300, 600],
    ],
    sizeMapping: [
      [
        [breakpoints.md, 0],
        [
          [300, 250],
          [300, 600],
        ],
      ],
      [[0, 0], []],
    ],
    tiers: [],
    adUnitSubVersion: 0,
  },
  desktopInlineSerpAd: {
    adUnit: "desktop-inline-serp-0",
    divId: "desktop-inline-serp",
    sizes: "fluid",
    tiers: [],
    adUnitSubVersion: 0,
  },
  inlineSegmentAd: {
    adUnit: "inline-flights-0",
    divId: "desktop-inline-segment",
    sizes: "fluid",
    tiers: [],
    adUnitSubVersion: 0,
  },
};
