import React, {
  ChangeEvent,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
} from "react";
import { IntlShape, useIntl } from "react-intl";
import { border_radius } from "src/design-system/tokens/border";
import { DraggableIcon } from "src/domain/TripPlanner/TripSearchBar/DraggableIcon";
import { CloseCircle } from "src/svg/CloseCircle";
import { DragHandle } from "src/svg/tripplanner/DragHandle";
import { useIsHotelsUrlDeeplink } from "src/utils/hooks/useNavigateToHotelsPage";
import { useForkRef } from "src/utils/useForkRef";
import styled, { css } from "styled-components";
import { color, fontSize, fontWeight, spacing, textColor } from "../../theme";

import { Icon } from "../Icon/Icon";
import messages from "./AutocompleteInput.messages";

type AutocompleteType = "origin" | "destination" | "hotelDestination" | number;

type Props = {
  type?: AutocompleteType;
  value: string;
  onChange: (query: string) => void;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  autoFocus?: boolean;
  id?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  darkHighlight?: boolean;
  solidBackground?: boolean;
  placeholder?: string;
  transparentBackground?: boolean;
  addDestinationRef?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  curvedBorder?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  isFocused?: boolean;
  showClearIcon?: boolean;
  handleClear?: () => void;
  horizontalSearch?: boolean;
  index?: number;
  role?: string;
  isDraggable?: boolean;
};

export function AutocompleteInput(props: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const labelMsg = getAutocompleteInputLabelMsg(intl, props.type);
  const placeholderMsg = getAutocompleteInputPlaceHolderMsg(intl, props.type);

  const placeholderProps = props.placeholder ?? placeholderMsg;

  const id = props.id ?? "autocomplete";

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    props.onChange(e.currentTarget.value);
  }

  useEffect(() => {
    if (props.autoFocus && ref?.current) {
      ref.current.select();
    }
  }, [props.autoFocus, ref]);

  return (
    <SolidBackgroundWrapper
      className={props.className}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      noShadow={props.noShadow}
      noBorder={props.noBorder}
      isFocused={props.isFocused}
      hasIcon={!!props.icon}
      transparentBackground={props.transparentBackground}
      horizontalSearch={props.horizontalSearch}
      isDraggable={props.isDraggable}
      isHotelsScreen={isHotelsScreen}
      role={props.role}
    >
      {/** If showing minimal styled input or icon, don't show label */}
      {labelMsg && !props.transparentBackground && !props.icon && (
        <Label htmlFor={id}>{labelMsg}</Label>
      )}
      {props.icon}

      {props.isDraggable ? (
        <DraggableIcon size="lg">
          <DragHandle tint="grey4" />
        </DraggableIcon>
      ) : null}

      <Input
        data-testid={`autocomplete-${props.type}`}
        id={id}
        ref={useForkRef(ref, props.addDestinationRef)}
        title={labelMsg ?? placeholderProps}
        value={props.value}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        // We can disable this autoFocus rule because we only ever have one instance
        // in the DOM at any one time.
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.autoFocus}
        placeholder={placeholderProps}
        darkHighlight={props.darkHighlight}
        transparentBackground={props.transparentBackground}
        fullWidth={props.fullWidth}
        data-no-dnd="true"
        type="search"
      />

      {props.showClearIcon && props.handleClear && (
        <IconWrapper onClick={props.handleClear}>
          <Icon size="md">
            <CloseCircle tint="n60" />
          </Icon>
        </IconWrapper>
      )}
    </SolidBackgroundWrapper>
  );
}

export function getAutocompleteInputLabelMsg(
  intl: IntlShape,
  type: AutocompleteType | undefined
) {
  const labelMessages = {
    origin: intl.formatMessage(messages.origin),
    destination: intl.formatMessage(messages.destination),
  };

  return type === "origin" || type === "destination"
    ? labelMessages[type]
    : undefined;
}

export function getAutocompleteInputPlaceHolderMsg(
  intl: IntlShape,
  type: AutocompleteType | undefined
) {
  const placeholderMessages = {
    origin: intl.formatMessage(messages.originPlaceholder),
    destination: intl.formatMessage(messages.destinationPlaceholder),
  };
  return type === 0 || type === "origin"
    ? placeholderMessages.origin
    : placeholderMessages.destination;
}

const SolidBackgroundWrapper = styled.div<{
  disabled?: boolean;
  fullWidth?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  isFocused?: boolean;
  hasIcon?: boolean;
  transparentBackground?: boolean;
  horizontalSearch?: boolean;
  isDraggable?: boolean;
  isHotelsScreen?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 1px solid ${color.n40};
  border-radius: ${border_radius.rounded_md};
  background-color: ${(props) =>
    props.transparentBackground ? "transparent" : color.white};
  min-height: 44px;
  padding-left: 6px;

  & input {
    margin-left: ${spacing.sm};
  }

  ${({ horizontalSearch }) =>
    !horizontalSearch &&
    css`
      padding: ${spacing.lg};
      padding-left: 6px;
    `}

  ${({ horizontalSearch }) =>
    horizontalSearch &&
    css`
      & input {
        width: 194px;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}

  ${({ horizontalSearch, isDraggable }) =>
    horizontalSearch &&
    isDraggable &&
    css`
      & input {
        flex: unset;
        margin-left: ${spacing.sm};
      }
    `}

  ${({ noBorder }) =>
    !noBorder &&
    css`
      &:hover {
        border-color: ${color.cod};
      }
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      & input {
        margin-left: ${spacing.lg};
      }
    `}

  ${({ disabled }) => disabled && `background-color: ${color.n10}`}

  ${({ noShadow }) =>
    noShadow &&
    css`
      box-shadow: none;
    `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

    ${({ isFocused, noBorder }) =>
    isFocused &&
    !noBorder &&
    css`
      border-color: ${color.cod};
    `}

    ${({ isHotelsScreen }) =>
    isHotelsScreen &&
    css`
      padding-left: ${spacing.lg};
    `}
`;

const Label = styled.label`
  text-transform: uppercase;
  color: ${color.pink};
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.medium};
  padding-right: ${spacing.lg};
`;

const Input = styled.input<{
  darkHighlight?: boolean;
  transparentBackground?: boolean;
  fullWidth?: boolean;
}>`
  flex: 1 0 auto;
  border: none;
  font-size: ${fontSize.body};
  color: ${textColor.primaryOnLight};

  // Truncate long addresses in the input
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  background-color: ${(props) =>
    props.transparentBackground ? "transparent" : color.white};

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${color.n10};
  }

  ${({ darkHighlight }) =>
    darkHighlight &&
    css`
      &::selection {
        background: ${color.n300};
        color: ${color.white};
      }
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

    &::-webkit-search-cancel-button {
    display: none;
  }
`;

const IconWrapper = styled.button`
  position: relative;
  left: -${spacing.xl};
  top: 2px;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }
`;
