import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { formatPriceRange } from "src/components/FormattedPriceRange/FormattedPriceRange";
import { Icon } from "src/components/Icon/Icon";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Actions } from "src/components/TripPlanner/Actions/Actions";
import { useFeature } from "src/feature/useFeature";
import { Bookmark } from "src/svg/tripplanner/Bookmark";
import { color, fontSize, spacing } from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import { lowestPriceRangeFromSearch } from "src/utils/adapters/priceRange";
import { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import useUser from "src/utils/hooks/useUser";
import styled from "styled-components";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Dot } from "../Dates/LocationNights";
import { TripDatePickerButton } from "../Dates/TripDatePickerButton";
import messages from "../TripPlannerScreen.messages";
import { UserAccountTakeover } from "../UserAccountTakeover/UserAccountTakeover";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

export default function LargeHeading() {
  const intl = useIntl();
  const [showSigninTakeover, setShowSigninTakeover] = useState(false);
  const layout = useLayout();
  const { currencyCode, user } = useUser();
  const {
    tripRoutes,
    tripPlannerDetails,
    apiState,
    history,
    dispatch,
    tripDestination,
  } = useTripPlannerContext();
  const showTripDates = useFeature("DatesInTripPlanner") && layout !== "mobile";
  const { isLoading } = tripDestination;

  const locationCount = tripPlannerDetails.places.length;
  const subheadingText = intl.formatMessage(
    locationCount === 1
      ? messages.destinationsSingular
      : messages.destinationsPlural,
    { destinationCount: locationCount }
  );

  const isLoggedOut = !user?.id;

  const priceRange = useMemo(() => {
    const { lowRange, highRange } = getTripPriceRange(tripRoutes);
    if (highRange && lowRange) {
      return formatPriceRange(intl, lowRange, highRange, currencyCode);
    }
  }, [currencyCode, intl, tripRoutes]);

  function undo() {
    dispatch({ type: "UNDO" });
    sendAnalyticsInteractionEvent("TripPlanner", "Click:Undo");
  }

  function redo() {
    dispatch({ type: "REDO" });
    sendAnalyticsInteractionEvent("TripPlanner", "Click:Redo");
  }

  function handleBookmarkClick() {
    setShowSigninTakeover(true);
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:SaveTrip:MultipleTrips"
    );
  }

  return (
    <TripHeaderContainer>
      <InnerContentContainer>
        <DetailContainer>
          {isLoading || apiState.fetchState === "fetching" ? (
            <HeaderSkeleton />
          ) : (
            <>
              <Heading data-testid="trip-planner-title">
                {intl.formatMessage(messages.tripOverview)}
              </Heading>
              <Subheading data-testid="trip-subheading">
                {subheadingText}
                {priceRange && (
                  <>
                    <Dot $light />
                    <p>
                      {intl.formatMessage(messages.travel)} {priceRange}
                    </p>
                  </>
                )}
              </Subheading>
            </>
          )}
        </DetailContainer>
        <ActionsContainer>
          {history && (
            <Actions
              undoCallback={undo}
              redoCallback={redo}
              canUndo={history.canUndo}
              canRedo={history.canRedo}
            />
          )}
          {isLoggedOut && (
            <Tooltip
              id="save-button-heart-tooltip"
              anchor="center"
              placement="bottom"
              placementOffset={5}
              tooltipContent={
                <TooltipText>{intl.formatMessage(messages.save)}</TooltipText>
              }
            >
              <SaveButton
                data-testid="save-trip-signin-button"
                title={intl.formatMessage(messages.save)}
                onClick={handleBookmarkClick}
              >
                <Icon size="md">
                  <Bookmark tint="iconForeground" />
                </Icon>
                <UserAccountTakeover
                  isDropdownOpen={showSigninTakeover}
                  handleClose={() => setShowSigninTakeover(false)}
                  handleClickOffDropdown={() => setShowSigninTakeover(false)}
                />
              </SaveButton>
            </Tooltip>
          )}
        </ActionsContainer>
      </InnerContentContainer>
      {showTripDates && <TripDatePickerButton />}
    </TripHeaderContainer>
  );
}

function HeaderSkeleton() {
  return (
    <>
      <Heading>
        <Skeleton
          height={spacing.xxl}
          width="256px"
          margin={`0 0 ${spacing.md}`}
        />
      </Heading>
      <Subheading>
        <Skeleton inline height={spacing.xl} width="25%" />
        <Dot $light />
        <Skeleton inline height={spacing.xl} width="25%" />
      </Subheading>
    </>
  );
}

function getTripPriceRange(tripRoutes: ReturnType<typeof useGetTripRoutes>) {
  let lowRange = 0;
  let highRange = 0;
  tripRoutes.queries.forEach((query) => {
    if (query.data) {
      const priceRange = lowestPriceRangeFromSearch(query.data);
      lowRange += priceRange ? priceRange.low : 0;
      highRange += priceRange ? priceRange.high : 0;
    }
  });
  return { lowRange, highRange };
}

const InnerContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${desktopLayout} {
    align-items: flex-start;
  }
`;

const DetailContainer = styled.div`
  ${desktopLayout} {
    max-width: 75%;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xl};
  margin-top: 0;
  ${desktopLayout} {
    margin-top: -5px;
  }
`;

const Heading = styled.h3`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${fontSize.h3};
  margin-bottom: ${spacing.sm};

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${desktopLayout} {
    white-space: normal;
    display: -webkit-box;
  }
`;

const Subheading = styled.span`
  display: flex;
  gap: ${spacing.md};
  align-items: center;
  font-size: ${fontSize.md};
`;
const SaveButton = styled(ButtonBase)`
  ${A11yOutline};

  &:hover {
    svg {
      path {
        stroke: ${(props) => props.theme.tripPlanActions.iconHover};
      }
    }
  }
`;

const TooltipText = styled.span`
  display: block;
  font-size: ${fontSize.sm};
  padding: 0;

  ${desktopLayout} {
    padding: ${spacing.sm} ${spacing.md};
  }
`;

const TripHeaderContainer = styled.div`
  position: relative;
  z-index: 1;
  padding: ${spacing.md} 0 0;

  ${SaveButton} {
    display: none;
  }

  ${desktopLayout} {
    background-color: ${color.white};
    max-width: 100%;
    padding: ${spacing.xxl} ${spacing.xl} ${spacing.xxl} ${spacing.xxl};
    margin-bottom: 0;
    ${SaveButton} {
      display: block;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      background-color: ${color.n30};
      height: 1px;
      position: absolute;
      bottom: 0;
      margin-top: ${spacing.xxl};
      left: 0;
    }
  }
`;
