import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Button } from "src/design-system/components/Button/Button";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { A11yOutline } from "src/utils/accessibility";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import styled from "styled-components";
import {
  GettingHereLoading,
  PricingInfoLoading,
  RatingWithBoxLoading,
  TagsLoading,
  TitleLoading,
} from "./LoadingComponents";
import { MIN_HEIGHT_DESKTOP_LIST_CARD } from "./constants";
import { CtaCopyTest } from "./CtaCopyTest";

type Props = {
  nightsStay: number;
};

export function ListCardDesktopLoading({ nightsStay }: Props) {
  const { isXlScreen } = useScreenMinWidth();
  const imgWidth = isXlScreen ? 250 : 200;

  return (
    <Card
      role="button"
      aria-disabled
      tabIndex={0}
      data-testid="hotel-list-item"
    >
      <Skeleton width={imgWidth} height="" borderRadius="rounded_md" />
      <Contents>
        <Description>
          <TitleLoading />
          <RatingWithBoxLoading />
          <TagsLoading />
          <GettingHereLoading />
        </Description>
        <Pricing>
          <PricingInfoLoading nightsStay={nightsStay} />
          <Button isDisabled>
            <CtaCopyTest />
          </Button>
        </Pricing>
      </Contents>
    </Card>
  );
}

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  padding: ${spacing.sm};
  border-radius: ${border_radius.rounded_lg};
  border: 1px solid ${color.border.border};
  background-color: ${color.bg.fill.fill};
  min-height: ${MIN_HEIGHT_DESKTOP_LIST_CARD}px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  ${A11yOutline};
`;

const Contents = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  padding: ${spacing.xl};
  gap: ${spacing.xl};
`;

const Description = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: ${spacing.md};
`;

// Tries to place the pricing info and button side by side, but wraps neatly if it can't
const Pricing = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;

  gap: ${spacing.sm} ${spacing.xl};
`;
