import { useEffect } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { formatPrice } from "src/components/FormattedPrice/FormattedPrice";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Button } from "src/design-system/components/Button/Button";
import { Typography } from "src/design-system/components/Typography/Typography";
import { border_radius } from "src/design-system/tokens/border";
import { useHotelsContext } from "src/domain/HotelsScreen/HotelsContext";
import { HotelViewModel } from "src/domain/HotelsScreen/hotelsScreenAdapter";
import HotelPinMessages from "src/domain/TripPlanner/TripHotelMapPin/TripHotelMapPin.messages";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { color, fontWeight, spacing } from "src/theme";
import { useHotelInfo } from "src/utils/hooks/useHotelInfo";
import { useHotelList } from "src/utils/hooks/useHotelList";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import styled from "styled-components";

import { HotelPromoMessages } from "./HotelPromo.messages";

export type HotelPromoProps = {
  title: string;
  originCanonical?: string;
  destinationCanonical: string;
};
export default function HotelPromo({
  title,
  originCanonical,
  destinationCanonical,
}: HotelPromoProps) {
  const intl = useIntl();
  const { setHotelListQueryParams } = useHotelsContext();
  const { hotelList } = useHotelList("live");
  const { navigateToHotels } = useNavigateToHotelsPage();

  useEffect(() => {
    setHotelListQueryParams((state) => {
      return {
        ...state,
        enabled: true,
        selectedHotelId: undefined,
      };
    });
  }, [setHotelListQueryParams]);

  function handleHotelClick() {
    sendAnalyticsInteractionEvent(
      "Destination",
      "Click:HotelPromo",
      destinationCanonical
    );
    navigateToHotels({
      originCanonical,
      destinationCanonical,
    });
  }

  if (!hotelList) return <HotelPromoSkeleton />;

  return (
    <HotelPromoLink onClick={handleHotelClick}>
      <LeftDiv>
        <Typography variant="heading-md" as="h2">
          {intl.formatMessage(HotelPinMessages.hotelsIn, { location: title })}
        </Typography>
        {hotelList[0].price && (
          <DescriptionSpan>
            {intl.formatMessage(HotelPromoMessages.from, {
              price: (
                <PriceSpan>{formatPrice(hotelList[0].price, intl)}</PriceSpan>
              ),
            })}
          </DescriptionSpan>
        )}
        <Button
          size="medium"
          variant="secondary"
          onPress={() => {
            sendAnalyticsInteractionEvent(
              "Destination",
              "Click:ViewHotels",
              destinationCanonical
            );
            navigateToHotels({
              originCanonical,
              destinationCanonical,
            });
          }}
        >
          {intl.formatMessage(HotelPromoMessages.viewHotels)}
        </Button>
      </LeftDiv>
      {hotelList && (
        <RightDiv>
          <HotelImage
            hotel={hotelList[2]}
            style={{
              opacity: 0.3,
              zIndex: 1,
              right: "0px",
              transform: "scale(0.6)",
            }}
          />
          <HotelImage
            hotel={hotelList[1]}
            style={{
              opacity: 0.8,
              zIndex: 2,
              left: "50%",
              transform: "translateX(-45%) scale(0.8)",
            }}
          />
          <HotelImage
            hotel={hotelList[0]}
            style={{ zIndex: 3, left: 0 }}
            icon={
              <HotelIconDiv>
                <HotelAlternative tint="pink" />
              </HotelIconDiv>
            }
          />
        </RightDiv>
      )}
    </HotelPromoLink>
  );
}

type HotelImageProps = {
  hotel: HotelViewModel;
  style: React.CSSProperties;
  icon?: React.ReactNode;
};

function HotelImage({ hotel, style, icon }: HotelImageProps) {
  const hotelInfo = useHotelInfo({
    hotelId: hotel.hotelId,
    provider: hotel.provider,
  });

  if (!hotelInfo.hotelInfoResponse) {
    return (
      <ImageFrameDiv style={style}>
        <Skeleton width="100%" height="100%" />
      </ImageFrameDiv>
    );
  }

  return (
    <ImageFrameDiv style={style}>
      <Image
        loading="lazy"
        src={hotelInfo.hotelInfoResponse.imageUrl}
        alt={hotel.title}
      />
      {icon}
    </ImageFrameDiv>
  );
}

export function HotelPromoSkeleton() {
  return (
    <HotelPromoLink as="div">
      <LeftDiv>
        <Skeleton width="160px" height="24px" />
        <Skeleton width="225px" height="20px" />
        <Skeleton width="100px" height="37px" />
      </LeftDiv>
      <RightDiv>
        <ImageFrameDiv
          style={{
            opacity: 0.3,
            zIndex: 1,
            right: "0px",
            transform: "scale(0.6)",
          }}
        >
          <Skeleton width="100%" height="100%" />
        </ImageFrameDiv>
        <ImageFrameDiv
          style={{
            opacity: 0.8,
            zIndex: 2,
            left: "50%",
            transform: "translateX(-45%) scale(0.8)",
          }}
        >
          <Skeleton width="100%" height="100%" />
        </ImageFrameDiv>
        <ImageFrameDiv style={{ zIndex: 3, left: 0 }}>
          <Skeleton width="100%" height="100%" />
        </ImageFrameDiv>
      </RightDiv>
    </HotelPromoLink>
  );
}

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.xl};
`;

const DescriptionSpan = styled.span`
  color: ${color.n200};
`;

const PriceSpan = styled.span`
  font-weight: ${fontWeight.bold};
  color: ${color.successDark};
`;

const RightDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 160px;
  height: 125px;
`;

const ImageFrameDiv = styled.div`
  position: absolute;
  background-color: ${color.white};
  border-radius: ${border_radius.rounded_xl};
  border: ${spacing.sm} solid ${color.white};
  box-shadow: 0px 8px 8px 0px ${color.n30};
  width: 90px;
  height: 125px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${border_radius.rounded_lg};
`;

const HotelIconDiv = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.white};
  outline: ${color.n30} solid 1px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  right: 0;
  top: 0;
  transform: translate(30%, -30%);
  svg {
    width: 24px;
    height: 24px;
  }
`;

const HotelPromoLink = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: ${spacing.xxxl} ${spacing.xxl};
  cursor: pointer;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
`;
