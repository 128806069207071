import LazyHotelLargeSearchBar from "./domain/HotelsScreen/HotelSearch/LazyHotelLargeSearchBar";
import { LargeSearchBar } from "./domain/LargeSearchBar/LargeSearchBar";
import { LazyTripSearchBar } from "./domain/TripPlanner/TripSearchBar/LazyTripSearchBar";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { useIsHotelsUrlDeeplink } from "./utils/hooks/useNavigateToHotelsPage";

export default function SearchBar() {
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const isTripScreen = useIsTripScreen();

  if (isHotelsScreen) {
    return <LazyHotelLargeSearchBar />;
  } else if (isTripScreen) {
    return <LazyTripSearchBar />;
  } else {
    return <LargeSearchBar />;
  }
}
